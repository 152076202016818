import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModalComponent } from '@core/classes';
import { disableForm, enableForm } from '@core/utilities';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent extends CommonModalComponent {
  /** If the modal is a form, this shuold be it's FormGroup. The main button
   * will be treated as the submit button and it's click event will only be
   * fired when the form is valid.
   */
  @Input() formGroup?: FormGroup;
  /** The modal's actual content. Can be HTML. */
  @Input() bodyContent?: string;
  /** Should the modal's body's text be centered? */
  @Input() textCenter = false;
  /** Should a cancel button be displayed? */
  @Input() showCancelButton = false;
  /** Should the modal have minimalist style? */
  @Input() minimalist = false;
  /** The cancel button's text. */
  @Input() cancelButtonText = 'Cancelar';
  /** The confirm button's text. */
  @Input() mainButtonText = 'Confirmar';
  /** Padding on footer is set 12px on y axis if is true . */
  @Input() footerSmallPadding = false;

  /**
   * Show a spinner in the main button instead of the text.
   * Invalid if `minimalist`.
   *
   * The modal can't be closed if this is true. You may have to enclose your
   * "modal closing" in a `setTimeout` if both actions occur at once.
   */
  @Input() set mainButtonSpinner(mainButtonSpinner: boolean | undefined) {
    this.__mainButtonSpinner = mainButtonSpinner;
    mainButtonSpinner ? disableForm(this.formGroup) : enableForm(this.formGroup);
  }
  get mainButtonSpinner(): boolean | undefined {
    return this.__mainButtonSpinner;
  }

  private __mainButtonSpinner?: boolean;

  doNothing() {}
}

<app-modal-body [(visible)]="visible" (hidden)="formArray.reset()" (hide)="closeModal()" [lock]="mainButtonSpinner">
  <div class="modal-header d-flex align-items-center pl-4 pr-5 py-2 justify-content-between border-bottom" *ngIf="title">
    <h5 class="modal-title font-weight-normal">{{ title }}</h5>
    <button type="button" class="btn-sm btn-close" (click)="closeModal()" *ngIf="showCloseButton">
      <i class="far fa-times"></i>
    </button>
  </div>

  <div class="modal-body px-5 pb-0">
    <app-alert [alertId]="idField"></app-alert>

    <app-checkbox-list
      [options]="options"
      [formArray]="formArray"
      [emptyText]="emptyText"
      [emptySubText]="emptySubText"
      [notFoundText]="notFoundText"
      [notFoundSubText]="notFoundSubText"
      [searchText]="searchText"
    ></app-checkbox-list>
  </div>

  <div class="modal-footer border-0 row align-items-center pt-0 pb-4 px-6 justify-content-end">
    <div class="col-3 m-0">
      <button
        type="button"
        class="btn no-btn btn-link font-weight-normal"
        (click)="closeModal()"
        [innerHtml]="cancelButtonText | safeHtml"
        [disabled]="mainButtonSpinner"
      ></button>
    </div>
    <button
      type="submit"
      role="button"
      class="btn-sm btn-block btn-primary col-3 m-0"
      style="padding: 0.625rem 1.25rem"
      (click)="handleMainButton()"
      [disabled]="
        !options.length || !formArray.dirty || (atLeastOneRequired && !atLeastOneSelected) || mainButtonSpinner || mainButtonDisabled
      "
    >
      <span [innerHtml]="mainButtonText | safeHtml" *ngIf="!mainButtonSpinner; else spinnerTemplate" class="m-0"></span>

      <ng-template #spinnerTemplate>
        <app-spinner [width]="21" [height]="21" [white]="true"></app-spinner>
      </ng-template>
    </button>
  </div>
</app-modal-body>

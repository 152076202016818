<section class="px-1 py-3 w-100">
  <div class="d-flex justify-content-between align-items-center mb-3" [class.mb-4]="!filteredSearch">
    <h6 class="font-weight-bold mb-2">{{ tableTitle }}</h6>
    <div class="d-flex justify-content-end align-items-center btn-panel">
      <ng-container *ngIf="tableSelectedButtons?.length && hasChecked">
        <button (click)="uncheckAllCheckboxes()" class="btn no-btn text-primary">Cancelar</button>

        <button
          *ngFor="let button of tableSelectedButtons"
          (click)="button.action(selectedRows)"
          class="btn btn-sm btn-selected btn-primary"
          [innerHtml]="button.__innerHtml ?? '' | safeHtml"
        ></button>
        <span class="separator m-0 p-0"></span>
      </ng-container>

      <button
        *ngFor="let button of tableButtons"
        (click)="button.action()"
        [innerHtml]="button.__innerHtml ?? '' | safeHtml"
        [disabled]="button.disabled"
        [ngClass]="button.secondary ? 'btn-secodary' : 'btn-primary'"
        class="btn btn-lg"
      ></button>
    </div>
  </div>

  <ng-container *ngIf="filteredSearch">
    <app-filtered-search
      [allItems]="data"
      [(visibleItems)]="visibleData"
      [textFn]="extractTextFn"
      [borderBottom]="true"
      [borderBottomNoMargin]="true"
      (visibleItemsChange)="updatePaginationLength($event)"
      [placeholder]="tableTitle ? 'Busque por ' + tableTitle?.toLowerCase() : 'Buscar...'"
    ></app-filtered-search>
  </ng-container>

  <ng-container *ngIf="visibleData.length; else emptyState">
    <div classs="table-responsive w-100">
      <table class="table table-hover w-100">
        <thead>
          <tr class="header-row">
            <th scope="col" class="py-3 pr-0 checkbox" *ngIf="tableSelectedButtons?.length">
              <input class="input-control" type="checkbox" [formControl]="headerCheckbox" />
            </th>
            <th scope="col" *ngFor="let col of header" class="py-3" [class.text-center]="col.textCenter">{{ col.text }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let row of visibleData | paginate: paginationConfig; index as index"
            [ngClass]="row.textClass ?? ''"
            class="data-row px-3"
          >
            <td
              scope="row"
              class="py-3 checkbox"
              [class.visible]="getCheckboxInCurrentPage(index).value"
              *ngIf="tableSelectedButtons?.length"
            >
              <input class="input-control" type="checkbox" [formControl]="getCheckboxInCurrentPage(index)" />
            </td>

            <td *ngFor="let item of row.cells; index as colIndex" class="py-3 flex-fill" [ngStyle]="{ width: header[colIndex].width }">
              <div class="d-flex align-items-center cell pt-2px">
                <span *ngIf="item.status" class="round-icon bg-{{ item.status }}"></span>
                <div class="d-flex flex-column" [ngClass]="{ 'text-center': item.textCenter, 'text-truncate': item.truncateText }">
                  <span [ngClass]="item.textClass ?? ''">{{ item.text }}</span>
                  <span *ngIf="item.subText" class="sub-text" [ngClass]="item.subTextClass ?? ''">{{ item.subText }}</span>
                </div>
              </div>
            </td>

            <ng-container
              [ngTemplateOutlet]="rowActionsPanel"
              [ngTemplateOutletContext]="{ actions: getRowActionsAt(row), row: row }"
              *ngIf="rowActions?.length"
            ></ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <app-pagination
      [paginationConfig]="paginationConfig"
      [totalItems]="visibleData.length"
      (pageChanged)="handlePageChange()"
    ></app-pagination>
  </ng-container>
</section>

<ng-template let-actions="actions" let-row="row" #rowActionsPanel>
  <td [ngClass]="row.double ? 'py-3' : 'py-12px'" [ngStyle]="{ width: header[header.length - 1].width }">
    <div class="flex-center actions-panel pt-2px">
      <div
        *ngFor="let action of actions"
        [popover]="evaluateTooltip(action.tooltipMessage, row, actionBtnElement) ?? ''"
        [placement]="evaluateTooltip(action.tooltipPlacement, row, actionBtnElement) ?? 'top'"
        [triggers]="evaluateTooltip(action.tooltipTriggers, row, actionBtnElement) ?? 'mouseenter:mouseleave'"
        [adaptivePosition]="false"
        container="body"
      >
        <button
          type="button"
          class="btn btn-sm flex-center"
          (click)="action.action(row, actionBtnElement)"
          [class.btn-big]="row.double"
          [attr.title]="action.title"
          #actionBtnElement
        >
          <i class="font-weight-light" [ngClass]="action.icon" *ngIf="!action.icon.endsWith('.svg'); else actionIconSvgTemplate"></i>

          <ng-template #actionIconSvgTemplate>
            <img [src]="action.icon" class="img-fluid p-0 m-0" />
          </ng-template>
        </button>
      </div>
    </div>
  </td>
</ng-template>

<ng-template #emptyState>
  <hr class="text-gray-800 mt-0 mb-7" />
  <app-empty-state
    [notFoundMessage]="notFoundMessage"
    [notFoundSubMessage]="notFoundSubMessage"
    [emptyListIcon]="emptyListIcon"
    [emptyListMessage]="emptyListMessage"
    [emptyListSubMessage]="emptyListSubMessage"
    [emptyListBtnText]="emptyListBtnText"
    [emptyListBtnIcon]="emptyListBtnIcon"
    (emptyListBtnClicked)="emptyListBtnAction.emit()"
    [qntItems]="visibleData.length"
    [qntItemsInitial]="totalRows"
  ></app-empty-state>
</ng-template>

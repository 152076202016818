import { Component } from '@angular/core';
import { FieldCommonComponent } from '@core/classes';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss'],
})
export class PasswordFieldComponent extends FieldCommonComponent {
  showPassword = false;

  showPasswordHandler() {
    this.showPassword = !this.showPassword;
  }
}

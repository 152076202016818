import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@ng-stack/forms';

/**
 * Handle `input` with `label`.
 *
 * Pay attention to both available `ng-content` types: `focus-container` and `no-focus-container`.
 * - `focus-container`: all elements that interact with `label`. When focused, these elements will trigger the label's animation.
 * - `no-focus-container`: all elements that do not interact with `label`.
 */
@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
})
export class InputGroupComponent {
  /** `FormControl`'s error, will be passed to the error component. */
  @Input() error: ValidationErrors;
  /** Form fild's `id`, will be passed to the `label`'s `for` attribute. */
  @Input() idField = '';
  /** Indicates if `FormControl` is valid. When `false` the `invalid` class is added to the `.form-control` element. */
  @Input() validControl = true;
  /** `label`'s text. */
  @Input() label = '';
  /** Should the overflow be shown? */
  @Input() overflow = false;
  /** Is this field readonly? */
  @Input() isReadonly?: boolean;

  /**
   * Is the form field filled? If so, the `label` receives the `.active` class.
   * Updated when the field blurs.
   */
  @Input() controlHasValue?: boolean;
}

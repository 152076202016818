import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FieldCommonComponent } from '@core/classes';
import { FormControl } from '@ng-stack/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

/**
 * Self-sufficient search bar. Filter a list by the searched expression.
 *
 * @example
 * ```html
 * <app-filtered-search
 *   [allItems]="applications"
 *   [(visibleItems)]="visibleApplications"
 *   [textFn]="extractTextFn"
 *   (searchedExpressionChange)="setSearchedExpression($event)"
 *   (itemsChange)="lengthPagesChange($event)"
 *   placeholder="Busque por aplicações"
 * ></app-filtered-search>

 * <button class="flex-center no-btn" *ngFor="let app of applications">
 *   <img [src]="app.icon" [alt]="app.name + '\'s icon'" />
 *   <span class="font-weight-normal ml-3">{{ app.name }}</span>
 * </button>
 * ```
 */
@Component({
  selector: 'app-filtered-search',
  templateUrl: './filtered-search.component.html',
  styleUrls: ['./filtered-search.component.scss'],
})
export class FilteredSearchComponent extends FieldCommonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder = '';
  @Input() border = false;
  @Input() borderBottom = false;
  @Input() borderBottomNoMargin = false;

  /** Items to be filtered. */
  @Input() allItems: any[];
  /** Filtered items. */
  @Input() get visibleItems(): any[] {
    return this.__visibleItems;
  }
  /** Function to extract text from each element in `items`. The text is used in comparison with the searched expression. */
  @Input() textFn: (item: any) => string;

  /** Expression searched by the user. */
  @Output() searchedExpressionChange = new EventEmitter<string>();
  /** Items list filter update. */
  @Output() visibleItemsChange = new EventEmitter<any>();

  private destroy$ = new Subject<void>();
  private __visibleItems: any[];

  control = new FormControl<string>(null);

  ngOnInit(): void {
    // timeout in order to prevent NG0100
    setTimeout(() => (this.visibleItems = this.allItems.map(item => item)));

    this.control.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        tap(value => {
          this.visibleItems = this.allItems.filter(item => this.textFn(item).toLowerCase().includes(value.toLowerCase()));
          this.searchedExpressionChange.emit(value);
        }),
      )
      .subscribe();

    if (this.borderBottomNoMargin) {
      this.borderBottom = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allItems && !changes.allItems.isFirstChange()) {
      this.resetSearch();
    } else if (changes.isReadonly) {
      changes.isReadonly.currentValue ? this.control.disable() : this.control.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  set visibleItems(items: any[]) {
    this.__visibleItems = items;
    this.visibleItemsChange.emit(this.visibleItems);
  }

  resetSearch(): void {
    this.control.setValue('');
  }
}

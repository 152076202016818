import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalBodyComponent } from './modal-body.component';

@NgModule({
  declarations: [ModalBodyComponent],
  imports: [CommonModule, ModalModule.forRoot()],
  exports: [ModalBodyComponent],
})
export class ModalBodyModule {}

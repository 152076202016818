<div class="alert alert-{{ type }} mb-5 flex-fill {{ size }}" role="alert" *ngIf="visible">
  <div class="d-flex justify-content-between align-items-center">
    <h6 class="alert-heading" [innerHtml]="title | safeHtml" *ngIf="title"></h6>
    <p class="font-weight-light m-0">
      <span [innerHtml]="message | safeHtml"></span>
      <button
        type="button"
        class="btn no-btn btn-link my-0 py-0"
        (click)="linkObservable?.subscribe()"
        [innerHtml]="linkedMessage ?? '' | safeHtml"
      ></button>
      <span [innerHtml]="trailingMessage ?? '' | safeHtml"></span>
    </p>
    <button type="button" class="no-btn" (click)="close()">
      <i class="far fa-times-circle"></i>
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { validateRoute } from '@core/utilities';
import { UserService } from '@services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanLoad, CanActivate {
  constructor(private readonly userService: UserService, private readonly router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateAdmin();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.validateAdmin();
  }

  private validateAdmin(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAdmin = this.userService.isAdmin();

    if (isAdmin === undefined) {
      return this.userService.fetchData().pipe(
        map(() => validateRoute(this.userService.isAdmin(), this.router)),
        catchError(() => of(false)),
      );
    }

    return validateRoute(isAdmin, this.router);
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiNotifitoken, applicationSelectorQuery, UserEndpoints } from '@core/utilities';
import { ApplicationUserModel, CreateUserModel, EditUserModel, ResponseModel, UserResponseModel } from '@models';
import { Observable } from 'rxjs';

@Injectable()
export class UserManagementService {
  constructor(private readonly http: HttpClient) {}

  getUserList<T extends string>(
    applicationId?: T,
  ): Observable<ResponseModel<T extends string ? ApplicationUserModel[] : UserResponseModel[]>> {
    return this.http.get<ResponseModel<T extends string ? ApplicationUserModel[] : UserResponseModel[][]>>(
      apiNotifitoken(UserEndpoints.MultipleUsers),
      {
        params: applicationId && { [applicationSelectorQuery]: applicationId },
      },
    );
  }

  createUser(user: CreateUserModel): Observable<ResponseModel<UserResponseModel>> {
    return this.http.post<ResponseModel<UserResponseModel>>(apiNotifitoken(UserEndpoints.CreateUser), user);
  }

  editUser(id: string, body: EditUserModel): Observable<ResponseModel<UserResponseModel>> {
    return this.http.patch<ResponseModel<UserResponseModel>>(apiNotifitoken(UserEndpoints.SingleUser, id), body);
  }

  deleteUsers(ids: string[]): Observable<ResponseModel<void>> {
    let params = new HttpParams();
    ids.forEach((id, index) => {
      params = params.append(`id[${index}]`, id);
    });

    return this.http.delete<ResponseModel<void>>(apiNotifitoken(UserEndpoints.MultipleUsers), { params });
  }
}

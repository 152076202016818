import { CheckboxOptionModel } from '@models';
import { FormArray, FormControl } from '@ng-stack/forms';

export class CheckboxListManager {
  private __data: CheckboxOptionModel[];

  constructor(public readonly formArray: FormArray<boolean>, data: CheckboxOptionModel[] = [], selectedIds: string[] = []) {
    this.setDataAndSelectedIds(data, selectedIds);
  }

  setDataAndSelectedIds(data: CheckboxOptionModel[], selectedIds: string[]) {
    const checkedSet = new Set(selectedIds);

    this.__data = data;
    this.formArray.clear();

    data.forEach((item, i) => {
      this.formArray.push(new FormControl<boolean>(checkedSet.has(item.id)));
      data[i].index = i;
    });
  }

  get selectedIds(): string[] {
    return this.formArray.value.reduce((acc: string[], cur, index) => {
      if (cur) {
        acc.push(this.data[index].id);
      }

      return acc;
    }, []);
  }

  get data(): CheckboxOptionModel[] {
    return this.__data;
  }

  set selectedIds(selectedIds: string[]) {
    const checkedSet = new Set(selectedIds);

    this.data.forEach((item, index) => {
      if (checkedSet.has(item.id)) {
        this.formArray.at(index).setValue(true);
      }
    });
  }

  set data(data: CheckboxOptionModel[]) {
    this.__data = data;
    this.formArray.clear();
    data.forEach((_, i) => {
      this.formArray.push(new FormControl<boolean>(null));
      data[i].index = i;
    });
  }
}

import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthenticationDetails, CognitoAccessToken, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Observable, Observer, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private readonly userPool = new CognitoUserPool({
    ClientId: environment.cognitoAppClientId,
    UserPoolId: environment.cognitoUserPoolId,
  });

  private __accessToken: CognitoAccessToken | null;
  private __cognitoUser: CognitoUser | null;
  private __isFirstLogin = false;

  /**
   * Execute an user's login.
   *
   * @param email user's email.
   * @param password user's password.
   * @returns the observable's result determines if this is the user's first login.
   */
  signIn(email: string, password: string): Observable<boolean> {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    this.__cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return new Observable<boolean>((observer: Observer<boolean>) => {
      this.__cognitoUser?.authenticateUser(authDetails, {
        onSuccess: result => {
          this.__accessToken = result.getAccessToken();
          this.__isFirstLogin = false;
          observer.next(false);
        },
        onFailure: err => observer.error(err),
        newPasswordRequired: () => {
          this.__isFirstLogin = true;
          observer.next(true);
        },
      });
    });
  }

  signOut(): void {
    this.user?.signOut();
    this.__cognitoUser = null;
    this.__accessToken = null;
  }

  isLogged(): Observable<boolean> {
    const cognitoUser = this.user;

    return this.getSession(cognitoUser).pipe(
      map(session => {
        this.__accessToken = session.getAccessToken();

        return true;
      }),
      catchError(() => {
        this.signOut();

        return of(false);
      }),
    );
  }

  setNewPassword(oldPassword: string, newPassword: string) {
    return new Observable<void>((observer: Observer<void>) => {
      const cognitoUser = this.user;

      this.getSession(cognitoUser)
        .pipe(
          tap(() => {
            cognitoUser?.changePassword(oldPassword, newPassword, (err, result) => {
              if (err) {
                observer.error(err);
              } else if (result === 'SUCCESS') {
                observer.next();
              } else {
                observer.error('Failed to change password');
              }
            });
          }),
          catchError(err => {
            observer.error(err);

            return throwError(err);
          }),
        )
        .subscribe();
    });
  }

  getSession(user: CognitoUser | null): Observable<CognitoUserSession> {
    return new Observable<CognitoUserSession>(observer => {
      if (!user) {
        observer.error('Failed to retrieve user');
      } else {
        user.getSession((err: Error | null, session: CognitoUserSession) => {
          if (err) {
            observer.error(err);
          } else if (session.isValid()) {
            observer.next(session);
          } else {
            observer.error('Session is invalid');
          }
        });
      }
    });
  }

  setFirstPassword(password: string): Observable<void> {
    return new Observable<void>((observer: Observer<void>) => {
      if (this.user) {
        this.user.completeNewPasswordChallenge(password, [], {
          onSuccess: result => {
            this.__accessToken = result.getAccessToken();
            this.__isFirstLogin = false;
            observer.next();
          },
          onFailure: err => observer.error(err),
        });
      } else {
        observer.error({ status: 404, message: "There's not user to set a new password to." });
      }
    });
  }

  startForgotPasswordChallenge(email: string): Observable<void> {
    this.__cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return new Observable<void>((observer: Observer<void>) => {
      if (this.user) {
        this.user.forgotPassword({
          onSuccess: () => observer.next(),
          onFailure: err => {
            console.log(err.message || JSON.stringify(err));
            observer.error(err);
          },
          inputVerificationCode: () => observer.next(),
        });
      } else {
        observer.error({ status: 404, message: "There's not user to recover password for." });
      }
    });
  }

  completeForgotPasswordChallenge(authCode: string, password: string): Observable<void> {
    return new Observable<void>((observer: Observer<void>) => {
      if (this.user) {
        this.user.confirmPassword(authCode, password, {
          onSuccess: () => observer.next(),
          onFailure: err => observer.error(err),
        });
      } else {
        observer.error({ status: 404, message: "There's not user to recover password for." });
      }
    });
  }

  get user(): CognitoUser | null {
    return this.userPool.getCurrentUser() ?? this.__cognitoUser;
  }

  get accessToken(): CognitoAccessToken | null {
    return this.__accessToken;
  }

  get isFirstLogin(): boolean {
    return this.__isFirstLogin;
  }
}

<app-input-group
  *ngIf="!clean; else inputTemplate"
  idField="{{ idField }}Input"
  [error]="array.errors"
  [validControl]="(array.touched && array.valid) || !array.touched"
  [label]="label"
  [controlHasValue]="array.touched && !!array.value"
  [overflow]="true"
>
  <ng-container *ngTemplateOutlet="inputTemplate" focus-container></ng-container>
</app-input-group>

<ng-template #inputTemplate>
  <div
    class="d-flex align-items-center input-control w-100 h-100 px-3 pt-1"
    [style]="clean ? { border: 'none' } : {}"
    [ngClass]="scrollbarVisible ? 'pb-0' : 'pb-1'"
    (focus)="checkScrollbarVisible(tagsField)"
    (blur)="checkScrollbarVisible(tagsField)"
    (load)="checkScrollbarVisible(tagsField)"
    #tagsField
  >
    <div
      *ngFor="let email of array.value; index as index"
      class="tag d-flex justify-content-between align-items-center pl-1"
      [class.active]="activeTag === index"
    >
      <span class="text-truncate tag-text" (click)="editTagAt(index, actualInput)" [title]="email">{{ email }}</span>
      <button class="flex-center btn no-btn tag-delete" type="button" (click)="removeTagAt(index)">
        <i class="far fa-times m-0 p-0"></i>
      </button>
    </div>
    <input
      id="{{ idField }}Input"
      name="{{ idField }}InputName"
      class="flex-fill h-100 border-0"
      style="font-weight: inherit; outline: none"
      [ngStyle]="{
        width: isInputOverflowing(actualInput) || inputFocused ? 'auto' : '5%',
        'caret-color': isActiveTagValid() ? 'transparent' : 'auto'
      }"
      [placeholder]="!this.array.length ? placeholder : ''"
      [formControl]="control"
      [maxlength]="maxLength"
      [minlength]="minLength"
      [readonly]="isReadonly"
      [type]="type"
      (blur)="this.blurEvent.emit()"
      (click)="clickEvent.emit()"
      (focus)="focusEvent.emit()"
      (keydown.backspace)="handleBackspace()"
      (keydown.space)="handleSaveTagKeypress($event)"
      (keydown.enter)="handleEnterKeypress($event)"
      (keydown)="handleGenericKeypress($event)"
      (paste)="handlePaste()"
      (copy)="handleCopy()"
      (keyup.arrowleft)="handleArrowKeypress('left')"
      (keyup.arrowright)="handleArrowKeypress('right')"
      #actualInput
    />
  </div>
</ng-template>

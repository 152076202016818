import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

/**
 * Pagination controls for a given list of items.
 *
 * The pagination should also be added as a `pipe` to the list's `*ngFor`. If
 * `FilteredSearchComponent` is also used, the total number of data should be
 * updated every time a search happens.
 *
 * @example
 * ```typescript
 * paginationConfig: PaginationInstance = paginationConfig();
 * applications: ApplicationModel[] = [
 *   {
 *     name: 'My Promo 0',
 *     icon: 'assets/images/applications/MyPromo.png',
 *   },
 * ];
 *
 * extractTextFn(item: ApplicationModel) {
 *   return item.name;
 * }
 *
 * lengthPagesChange(applications: ApplicationModel[]) {
 *   this.paginationConfig.totalItems = applications.length;
 * }
 * ```
 *
 * @example
 * ```html
 * <div class="applications-container d-flex flex-column">
 *   <app-filtered-search
 *     [(visibleItems)]="visibleApplications"
 *     [(allItems)]="applications"
 *     [textFn]="extractTextFn"
 *     (itemsChange)="lengthPagesChange($event)"
 *   ></app-filtered-search>

 *   <button class="no-btn" *ngFor="let app of applications | paginate: paginationConfig">
 *     <img [src]="app.icon" [alt]="app.name + '\'s icon'" />
 *     <span class="font-weight-normal ml-3">{{ app.name }}</span>
 *   </button>
 * </div>

 * <app-pagination
 *   [paginationConfig]="paginationConfig"
 *   [totalItems]="visibleApplications.length"
 * ></app-pagination>
 * ```
 */
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() totalItems: number;
  @Input() paginationConfig: PaginationInstance;
  @Input() forceUpdateItemsPerPage?: Observable<number | void>;

  /** Should the number of items per page be updated depending on the screen size? */
  @Input() responsiveItemsPerPage = true;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();

  private initialItemsPerPage: number;
  private destroy$ = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateItemsPerPage();
  }

  ngOnInit(): void {
    this.initialItemsPerPage = this.paginationConfig.itemsPerPage;
    this.updateItemsPerPage();

    this.forceUpdateItemsPerPage
      ?.pipe(
        takeUntil(this.destroy$),
        tap(n => {
          setTimeout(() => {
            if (n) {
              this.paginationConfig.itemsPerPage = Math.max(this.paginationConfig.itemsPerPage + n, 1);
              setTimeout(() => {
                this.itemsPerPageChanged.emit(this.paginationConfig.itemsPerPage);
              });
            } else {
              this.updateItemsPerPage();
            }
          });
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalItems) {
      this.paginationConfig.totalItems = changes.totalItems.currentValue;
      setTimeout(() => this.pageChange(1));
    }
  }

  ngOnDestroy(): void {
    this.pageChanged.complete();
    this.itemsPerPageChanged.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  pageChange(page: number) {
    const { itemsPerPage } = this.paginationConfig;
    const length = this.totalItems;

    this.paginationConfig.currentPage = length / itemsPerPage === page - 1 ? page - 1 : page;
    this.pageChanged.emit(page);
  }

  private updateItemsPerPage() {
    if (this.responsiveItemsPerPage) {
      const height = window.innerHeight;

      // timeout in order to prevent NG0100
      setTimeout(() => {
        const previous = this.paginationConfig.itemsPerPage;

        if (height <= 768) {
          this.paginationConfig.itemsPerPage = this.initialItemsPerPage - 2;
        } else if (height <= 810) {
          this.paginationConfig.itemsPerPage = this.initialItemsPerPage - 1;
        } else if (height <= 900) {
          this.paginationConfig.itemsPerPage = this.initialItemsPerPage;
        } else if (height <= 1080) {
          this.paginationConfig.itemsPerPage = this.initialItemsPerPage + 1;
        } else {
          this.paginationConfig.itemsPerPage = this.initialItemsPerPage + 2;
        }
        this.paginationConfig.itemsPerPage = Math.max(this.paginationConfig.itemsPerPage, 1);

        const current = this.paginationConfig.itemsPerPage;

        if (previous !== current) {
          setTimeout(() => {
            this.itemsPerPageChanged.emit(current);
          });
        }
      });
    }
  }
}

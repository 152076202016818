import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgStackFormsModule } from '@ng-stack/forms';
import { SafeHtmlModule } from '@shared/pipes';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmptyStateModule } from '../empty-state';
import { FilteredSearchModule } from '../filtered-search';
import { PaginationModule } from '../pagination';
import { GenericTableComponent } from './generic-table.component';

@NgModule({
  declarations: [GenericTableComponent],
  imports: [
    CommonModule,
    EmptyStateModule,
    FilteredSearchModule,
    NgStackFormsModule,
    NgxPaginationModule,
    PaginationModule,
    PopoverModule.forRoot(),
    SafeHtmlModule,
  ],
  exports: [GenericTableComponent],
})
export class GenericTableModule {}

import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  /**
   * Observable that'll be subscribed to if user clicks the "try again"
   * button. You must take care of unsubscription, this component won't do it.
   */
  @Input() tryAgainObservable: Observable<any>;
  /** Callback function to be passed to the `ErrorComponent.tryAgainObservable` subscription. */
  @Input() subscribeCallback?: (value: any) => {};
  /**
   * Function to be called when the user clicks the "try again" button.
   * Has precedence over `ErrorComponent.tryAgainObservable`.
   */
  @Input() tryAgainFunction?: Function;
  /** Path to the image to be used as icon. */
  @Input() image = 'assets/images/error/exclamation.svg';
  /** Error title - bigger text. */
  @Input() title = 'Ocorreu um erro';
  /** Leading part of the error description (before the "try again" button-link). */
  @Input() initialText = 'Você pode';
  /** "Try again" button-link. */
  @Input() linkText = ' tentar novamente';
  /** Trailing part of the error description (after the "try again" button-link). */
  @Input() finalText = ' mais tarde.';

  retry() {
    if (this.tryAgainFunction) {
      this.tryAgainFunction();
    } else if (this.subscribeCallback) {
      this.tryAgainObservable.subscribe(this.subscribeCallback);
    } else {
      this.tryAgainObservable.subscribe();
    }
  }
}

import { Injectable } from '@angular/core';
import { DefaultErrorMessages } from '@core/utilities';
import { AlertHookModel, AlertModel, AlertType } from '@models';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class AlertService {
  readonly alert$ = new ReplaySubject<AlertModel>(1, 200);
  readonly hide$ = new Subject<string>();
  readonly setGlobalHook$ = new ReplaySubject<AlertHookModel>(1, 100);

  show(alert: AlertModel) {
    this.alert$.next(alert);
  }

  hide(alertId: string) {
    this.hide$.next(alertId);
  }

  /**
   * Generic error alert with default message.
   * @param alertId the alert's identifier
   * @param linkObservable will be subscribed to if user clicks the "try again" button
   */
  error(alertId: string, linkObservable?: Observable<any> | (() => Observable<any>)) {
    this.show({
      id: alertId,
      type: AlertType.Error,
      message: DefaultErrorMessages.AlertGeneric,
      linkObservable,
    });
  }

  setGlobalHook(hook: AlertHookModel) {
    this.setGlobalHook$.next(hook);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedGuard, UnloggedGuard } from '@guards';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule),
    canLoad: [UnloggedGuard],
  },
  {
    path: '',
    loadChildren: () => import('./views/logged/logged.module').then(m => m.LoggedModule),
    canLoad: [LoggedGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LoggedGuard, UnloggedGuard],
})
export class AppRoutingModule {}

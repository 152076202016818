import { Component, Input } from '@angular/core';
import { FieldCommonComponent } from '@core/classes';
import { StringInputFieldType } from '@models';

@Component({
  selector: 'app-string-field',
  templateUrl: './string-field.component.html',
})
export class StringFieldComponent extends FieldCommonComponent {
  @Input() type: StringInputFieldType = 'text';
  @Input() placeholder = '';
  /** Should the field's `label` and `border` be removed. */
  @Input() clean?: boolean;

  validateNumberInput(event: KeyboardEvent): boolean {
    const len = this.control.value?.length || 0;

    return this.type !== 'number' || (!isNaN(+event.key) && len < +this.maxLength);
  }
}

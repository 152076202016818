import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * Abstract class to be extended by forms components.
 */
@Directive()
export class FieldCommonComponent {
  @Input() control: FormControl;
  @Input() idField = '';
  @Input() label = '';
  @Input() maxLength = '';
  @Input() minLength = '';
  @Input() isReadonly?: boolean;

  @Output() blurEvent = new EventEmitter<void>();
  @Output() clickEvent = new EventEmitter<void>();
  @Output() focusEvent = new EventEmitter<void>();
}

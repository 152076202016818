import { FormArray, FormGroup } from '@angular/forms';

/**
 * Place a given button's icon 12px to the left of it's text (innerHtml).
 *
 * @returns HTML expression for placing `icon` with a right margin of 12px to the left of `text`.
 */
export const mixButtonIconText = (text: string, icon?: string) => {
  const isImage = icon?.endsWith('.svg');
  const iconHtml = icon
    ? isImage
      ? `<img src='${icon}' class='mr-12px my-0 p-0 img-fluid'></img>`
      : `<i class='${icon} mr-12px'></i>`
    : '';

  return `<span class="flex-center">${iconHtml}${text}</span>`;
};

export const enableForm = (form?: FormGroup | FormArray) => {
  form?.enable();
  form?.markAsDirty();
  form?.markAsUntouched();
};

export const disableForm = (form?: FormGroup | FormArray) => {
  form?.disable();
  form?.markAsPristine();
  form?.markAsUntouched();
};

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarItem } from '@models';
import { AuthService, UserService } from '@services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  route: string;
  items: SidebarItem[] = [
    {
      title: 'Aplicações',
      icon: 'far fa-desktop',
      route: 'applications',
    },
    {
      title: 'Configurações',
      icon: 'far fa-cog',
      route: 'settings',
    },
    {
      title: 'Sair',
      icon: 'far fa-sign-out-alt',
      action: this.logout.bind(this),
    },
  ];

  userName: string;

  constructor(private readonly authService: AuthService, private readonly router: Router, private readonly userService: UserService) {}

  ngOnInit(): void {
    this.userName = this.userService.name.split(' ')[0];
    this.setUrl(this.router.url);
    this.subscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setUrl(e.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(['/login']);
  }

  private setUrl(url: string) {
    const regexp = /\/(?<route>[^#]*)(?<fragment>#.+)?$/;
    const groups = regexp.exec(url)?.groups ?? {};

    this.route = groups['route'];
  }
}

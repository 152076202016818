<div class="error-box text-center mx-auto flex-center flex-column">
  <img *ngIf="image" [src]="image" class="img-fluid" />
  <div class="error-text flex-center flex-column">
    <h5 class="error-title font-weight-normal my-0">{{ title }}</h5>
    <p class="error-info font-weight-light">
      {{ initialText }}
      <button type="button" class="btn no-btn btn-link border-0 font-weight-bold" (click)="retry()">
        {{ linkText }}</button
      >{{ finalText }}
    </p>
  </div>
</div>

export const enum CognitoAccessGroups {
  Admin = 'Admins',
}

export const enum CognitoUserAttributes {
  Email = 'email',
}

export interface AuthenticationDataModel {
  email: string;
  password: string;
}

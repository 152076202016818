import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StringFieldModule } from '@forms/string-field/string-field.module';
import { NgStackFormsModule } from '@ng-stack/forms';
import { FilteredSearchComponent } from './filtered-search.component';

@NgModule({
  declarations: [FilteredSearchComponent],
  imports: [CommonModule, StringFieldModule, NgStackFormsModule],
  exports: [FilteredSearchComponent],
})
export class FilteredSearchModule {}

import { RequestObjectOptionsModel, RequestType, ResponseModel } from '@models';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { doOnSubscribe } from './observable-pipes.util';

export class RequestObject<T> {
  private __requestType: RequestType = 'GET';
  private __response: T;

  /** The request's observable. */
  observable: Observable<ResponseModel<T>>;
  /** Is the request loading? */
  loading = false;
  /** Did the request result in error? */
  error = false;
  /** Did the request return empty data/404? */
  empty = false;

  constructor(requestObservable: Observable<ResponseModel<T>>, opts: RequestObjectOptionsModel = {}) {
    this.observable = requestObservable.pipe(
      opts.take1 !== false ? take(1) : tap(),
      doOnSubscribe(() => {
        this.loading = true;
        this.error = false;
        this.empty = false;

        if (opts.loading) {
          opts.loading.show();
        }
      }),
      tap((response: ResponseModel<T>) => {
        this.__response = response?.data;

        if (this.requestType === 'GET') {
          this.empty = this.isEmptyResponse(response);
        }

        return response;
      }),
      finalize(() => {
        this.loading = false;

        if (opts.loading) {
          opts.loading.hide();
        }
      }),
      catchError(error => {
        this.error = true;

        return throwError(error);
      }),
    );

    this.requestType = opts.requestType ?? 'GET';
  }

  static raw<T>(requestObservable: Observable<T>, opts: RequestObjectOptionsModel = {}) {
    const toResponseModel$ = requestObservable.pipe(
      map((response: T) => ({
        data: response,
      })),
    );

    return new RequestObject<T>(toResponseModel$, opts);
  }

  /** Is there content to show? (aka. not loading, no error and not empty) */
  showContent = () => !this.loading && !this.error && !this.empty;
  /** Is there no content/is it empty? */
  emptyCondition = () => !this.loading && !this.error && (this.empty || !this.response);

  set requestType(type: RequestType) {
    this.__requestType = type;
  }

  get requestType(): RequestType {
    return this.__requestType;
  }

  get response(): T {
    return this.__response;
  }

  private isEmptyResponse(response: ResponseModel<T> | ResponseModel<T>[]): boolean {
    // treat the case where the observable is from a
    // forkJoin - which returns an array of responses
    const responses = Array.isArray(response) ? response : [response];
    const hasEmptyResponse = responses.some(res => {
      const hasData = res?.hasOwnProperty('data');
      const isDataInvalid = res?.data === undefined || res?.data === null;
      const isDataEmptyArray = Array.isArray(res?.data) && !res?.data.length;

      return !hasData || isDataInvalid || isDataEmptyArray;
    });

    return hasEmptyResponse;
  }
}

import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthService } from '@services';
import { Observable } from 'rxjs';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let transformedReq: HttpRequest<any> = request;

    const allowedPaths: string[] = [];
    const interceptedPaths: string[] = ['application', 'user', 'email', 'auth'];

    const reducerFn = (acc: boolean, cur: string): boolean => acc || new RegExp(environment.backendUrl + cur, 'g').test(request.url);
    const isAllowed: boolean = allowedPaths.reduce(reducerFn, false);
    const isInterceptable: boolean = interceptedPaths.reduce(reducerFn, false);

    if (isInterceptable && !isAllowed) {
      transformedReq = request.clone({
        headers: new HttpHeaders({ authorization: `Bearer ${this.authService.accessToken?.getJwtToken()}` }),
      });
    }

    return next.handle(transformedReq);
  }
}

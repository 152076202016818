import { ValidationErrors } from '@ng-stack/forms';

export const enum DefaultErrorMessages {
  AlertGeneric = 'Ocorreu um erro. Tente novamente ou volte mais tarde.',
  TooManyRequests = 'Limite de requisições atingido, tente novamente mais tarde.',
}

export const ValidatorsErrorMessage: ValidationErrors = {
  email: 'E-mail inválido.',
  equalToEmail: 'A confirmação de e-mail deve ser igual ao e-mail',
  equalToPassword: 'A confirmação de senha deve ser igual à senha',
  fileMinSize: 'Esse arquivo é muito leve.',
  fileMaxSize: 'Esse arquivo é muito pesado.',
  fileRequired: 'É necessário adicionar pelo menos um arquivo.',
  filesMaxLength: '',
  filesMinLength: '',
  max: '',
  maxlength: (err: { requiredLength: number; actualLength: number }) => `Este campo deve ter, no máximo, ${err.requiredLength} caracteres.`,
  min: '',
  minlength: (err: { requiredLength: number; actualLength: number }) => `Este campo deve ter, no mínimo, ${err.requiredLength} caracteres.`,
  pattern: '',
  pwHaveForbiddenCharacters: 'A senha possui caracteres não permitidos',
  pwMinimumLength: 'A senha precisa ter, no mínimo, 8 caracteres.',
  pwNotHaveCapitalCase: 'A senha precisa ter pelo menos uma letra maiúscula',
  pwNotHaveNumber: 'A senha precisa ter pelo menos um número',
  pwNotHaveSmallCase: 'A senha precisa ter pelo menos uma letra minúscula',
  pwNotHaveSpecialCharacters: 'A senha precisa ter caracteres especiais',
  required: 'Campo obrigatório',
};

import { Router } from '@angular/router';
import { execIfFunction } from './function.utils';

/** Redirect to path if condition is false, do nothing otherwise.*/
export const validateRoute = (condition: boolean | (() => boolean), router: Router, path = ''): boolean => {
  if (execIfFunction(condition)) {
    return true;
  }

  router.navigate(['/' + path]);

  return false;
};

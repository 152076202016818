import { Component, Input, OnInit } from '@angular/core';
import { GlobalSpinnerService } from '@services';

@Component({
  selector: 'app-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.scss'],
})
export class GlobalSpinnerComponent {
  @Input() paddingLeft = 0;

  constructor(public readonly globalSpinnerService: GlobalSpinnerService) {}
}

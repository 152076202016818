<div
  class="form-control position-relative"
  [class.invalid]="!validControl"
  [class.valid]="controlHasValue && validControl"
  [class.readonly]="isReadonly"
>
  <div class="form-container d-flex flex-row-reverse align-items-center h-100" [ngClass]="overflow ? 'overflow-auto' : 'overflow-hidden'">
    <ng-content select="[no-focus-container]"></ng-content>

    <div class="form-focus-container d-flex">
      <ng-content select="[focus-container]"></ng-content>
    </div>

    <label *ngIf="label" class="form-control-placeholder position-absolute" [class.active]="controlHasValue" [for]="idField">
      {{ label }}
    </label>
  </div>

  <div class="ml-3">
    <app-error-message [for]="idField" [error]="error"></app-error-message>
  </div>
</div>

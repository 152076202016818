import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckboxListModule } from '@forms';
import { ModalBodyModule } from '@modal/modal-body/modal-body.module';
import { AlertModule } from '@shared/components/alert';
import { SpinnerModule } from '@shared/components/spinner';
import { SafeHtmlModule } from '@shared/pipes';
import { ModalCheckboxListComponent } from './modal-checkbox-list.component';

@NgModule({
  declarations: [ModalCheckboxListComponent],
  imports: [AlertModule, CheckboxListModule, CommonModule, ModalBodyModule, SafeHtmlModule, SpinnerModule],
  exports: [ModalCheckboxListComponent],
})
export class ModalCheckboxListModule {}

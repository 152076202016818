<app-input-group
  idField="{{ idField }}Input"
  [error]="control.errors"
  [validControl]="(control.touched && control.valid) || !control.touched"
  [label]="label"
  [controlHasValue]="!!control.value"
>
  <app-string-field
    focus-container
    class="flex-fill"
    [clean]="true"
    [control]="control"
    [idField]="idField"
    [label]="label"
    [type]="showPassword ? 'text' : 'password'"
    (blurEvent)="blurEvent.emit()"
    (clickEvent)="clickEvent.emit()"
    (focusEvent)="focusEvent.emit()"
  ></app-string-field>

  <button no-focus-container type="button" class="no-btn flex-center pl-2 pr-3" (click)="showPasswordHandler()">
    <i class="fal fa-{{ showPassword ? 'eye' : 'eye-slash' }} flex-center text-gray-600"></i>
  </button>
</app-input-group>

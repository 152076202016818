<pagination-template
  #pagination="paginationApi"
  (pageChange)="pageChange($event)"
  (pageBoundsCorrection)="pageChange($event)"
  [maxSize]="7"
></pagination-template>

<nav *ngIf="pagination.pages.length > 1" class="flex-center">
  <div class="page-item mr-7" [class.disabled]="pagination.isFirstPage()">
    <a class="page-link page-link-chevron" (click)="pagination.previous()">
      <i class="fas fa-chevron-left font-weight-light"></i>
    </a>
  </div>

  <div class="d-flex pages-panel">
    <div
      class="page-item text-center cursor-pointer"
      *ngFor="let page of pagination.pages"
      [class.current]="pagination.getCurrent() === page.value"
    >
      <a class="page-link" (click)="pagination.setCurrent(page.value)">
        {{ page.label }}
      </a>
    </div>
  </div>

  <div class="page-item ml-7" [class.disabled]="pagination.isLastPage()">
    <a class="page-link page-link-chevron" (click)="pagination.next()">
      <i class="fas fa-chevron-right font-weight-light"></i>
    </a>
  </div>
</nav>

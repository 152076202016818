import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxListComponent } from './checkbox-list.component';
import { NgStackFormsModule } from '@ng-stack/forms';
import { FilteredSearchModule } from '@shared/components/filtered-search';

@NgModule({
  declarations: [CheckboxListComponent],
  imports: [CommonModule, FilteredSearchModule, NgStackFormsModule],
  exports: [CheckboxListComponent],
})
export class CheckboxListModule {}

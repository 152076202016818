import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { mixButtonIconText } from '@core/utilities';

/**
 * Display *empty-state* error message when a list is empty.
 *
 * It differentiates the cases when the list is originally empty and no data
 * exists (empty list case) and when the list is empty because of a filter but
 * data does actually exist (not found case).
 *
 * @example
 * ```html
 * <app-empty-state
 *   notFoundMessage="Nenhum resultado encontrado para <strong>{{ searchedExpression }}</strong>."
 *   emptyListMessage="Você não está associado a nenhuma aplicação."
 *   [qntItems]="applications.length"
 *   *ngIf="!applications.length"
 * ></app-empty-state>
 * ```
 */
@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent implements OnInit {
  /** Number of items initially in the list. */
  @Input() qntItemsInitial: number;
  /** Number of items currently in the list. */
  @Input() qntItems: number;
  /** Path to the image to be used as icon. */
  @Input() notFoundIcon = 'assets/images/empty-state/lupa.svg';
  /** Message for when the searched term was not found. */
  @Input() notFoundMessage = 'Nenhum resultado encontrado.';
  /** Smaller message to be displayed below the main one for when the searched term was not found. */
  @Input() notFoundSubMessage = 'Por favor, tente buscar por outro termo.';
  /** Path to the image to be used as icon. */
  @Input() emptyListIcon = 'assets/images/empty-state/lupa.svg';
  /** Message for when there was no data to display (without filtering). */
  @Input() emptyListMessage = 'Não existem dados a serem exibidos.';
  /** Smaller message to be displayed below the main one for when there was no data to display (without filtering). */
  @Input() emptyListSubMessage = 'Se houver um problema, fale com seu administrador.';
  /** Text to be displayed inside of the button that appears when there is no data. */
  @Input() emptyListBtnText?: string;
  /** Icon to be displayed inside of the button that appears when there is no data. */
  @Input() emptyListBtnIcon?: string;
  /** Triggered when the button that appears when there is no data is clicked. */
  @Output() emptyListBtnClicked = new EventEmitter<void>();

  /** Is the list always empty? As opposed to being the result of a filtered search. */
  isAlwaysEmpty: boolean;

  emptyListBtnInnerHtml: string;

  ngOnInit(): void {
    this.isAlwaysEmpty = !this.qntItemsInitial;
    if (this.emptyListBtnText) {
      this.emptyListBtnInnerHtml = mixButtonIconText(this.emptyListBtnText, this.emptyListBtnIcon);
    }
  }
}

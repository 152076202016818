<app-modal-body
  [(visible)]="visible"
  (hidden)="formGroup?.reset()"
  (hide)="closeModal()"
  [classes]="minimalist ? 'modal-sm' : ''"
  [lock]="mainButtonSpinner && !minimalist"
>
  <div [ngClass]="{ 'p-4': minimalist }">
    <div
      class="modal-header d-flex align-items-center"
      [ngClass]="{
        'border-0 justify-content-center mb-2 p-0': minimalist,
        'pl-4 pr-5 py-2 justify-content-between border-bottom': !minimalist
      }"
      *ngIf="title"
    >
      <h5 class="modal-title font-weight-normal">{{ title }}</h5>
      <button type="button" class="btn-sm btn-close" (click)="closeModal()" *ngIf="showCloseButton && !minimalist">
        <i class="far fa-times"></i>
      </button>
    </div>

    <form
      class="form-group m-0 p-0"
      *ngIf="formGroup && !minimalist; else bodyFooter"
      [appValidateSubmit]="formGroup"
      (successfulSubmissionEvent)="handleMainButton()"
    >
      <ng-content *ngTemplateOutlet="bodyFooter"></ng-content>
    </form>

    <ng-template #bodyFooter>
      <div
        class="modal-body"
        [ngClass]="{
          minimalist: minimalist,
          'text-center': textCenter,
          'p-5 pb-4': !minimalist,
          'p-0 mt-1 mb-4 font-weight-light': minimalist
        }"
      >
        <div *ngIf="bodyContent; else ngContentTemplate" [innerHtml]="bodyContent | safeHtml"></div>
        <ng-template #ngContentTemplate>
          <ng-content></ng-content>
        </ng-template>
      </div>

      <div
        class="modal-footer border-0 row align-items-center py-4 px-6 justify-content-end"
        [class.small-padding]="footerSmallPadding"
        *ngIf="!minimalist; else minimalistButtons"
      >
        <div class="col-3 m-0">
          <button
            type="button"
            class="btn no-btn btn-link font-weight-normal"
            (click)="closeModal()"
            [innerHtml]="cancelButtonText | safeHtml"
            *ngIf="showCancelButton"
            [disabled]="mainButtonSpinner"
          ></button>
        </div>
        <button
          class="btn-sm btn-block btn-primary col-3 m-0"
          [type]="formGroup ? 'submit' : 'button'"
          (click)="formGroup ? doNothing() : handleMainButton()"
          [disabled]="(formGroup ? !formGroup.dirty || !formGroup.valid : false) || mainButtonDisabled || mainButtonSpinner"
        >
          <span [innerHtml]="mainButtonText | safeHtml" *ngIf="!mainButtonSpinner; else spinnerTemplate"></span>

          <ng-template #spinnerTemplate>
            <app-spinner [width]="21" [height]="21" [white]="true"></app-spinner>
          </ng-template>
        </button>
      </div>

      <ng-template #minimalistButtons>
        <div class="modal-footer border-0 flex-center p-0">
          <button
            type="button"
            class="btn no-btn btn-link font-weight-normal mr-5"
            (click)="closeModal()"
            [innerHtml]="cancelButtonText | safeHtml"
            *ngIf="showCancelButton"
          ></button>
          <button
            class="btn no-btn btn-link font-weight-normal ml-5"
            [type]="formGroup ? 'submit' : 'button'"
            (click)="handleMainButton()"
            [innerHtml]="mainButtonText | safeHtml"
            [disabled]="mainButtonDisabled"
          ></button>
        </div>
      </ng-template>
    </ng-template>
  </div>
</app-modal-body>

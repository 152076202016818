<app-filtered-search
  [allItems]="options"
  [(visibleItems)]="visibleOptions"
  [textFn]="extractTextFn"
  [border]="true"
  [placeholder]="searchText"
  [isReadonly]="formArray.disabled"
  class="d-block mb-3"
></app-filtered-search>

<div class="options mb-n3 ml-2 d-flex flex-column text-center" [ngClass]="{ 'scroll-y': options.length, short: small }">
  <ng-container *ngIf="options.length; else emptyStateTemplate">
    <div *ngFor="let option of visibleOptions; let i = index; last as last" class="option">
      <div class="d-flex align-items-center py-3">
        <input class="input-control mr-3" type="checkbox" [formControl]="formArray.at(option.index ?? i)" />
        <p class="my-0">{{ option.name }}</p>
      </div>
      <hr *ngIf="!last" class="text-gray-800 my-0" />
    </div>
  </ng-container>
</div>

<hr class="checkbox-hr-bottom" />

<ng-template #emptyStateTemplate>
  <p>{{ isEmpty ? emptyText : notFoundText }}</p>
  <p class="font-weight-light">{{ isEmpty ? emptySubText : notFoundSubText }}</p>
</ng-template>

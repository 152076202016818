import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerModule } from '../spinner/spinner.module';
import { GlobalSpinnerComponent } from './global-spinner.component';

@NgModule({
  declarations: [GlobalSpinnerComponent],
  imports: [CommonModule, SpinnerModule],
  exports: [GlobalSpinnerComponent],
})
export class GlobalSpinnerModule {}

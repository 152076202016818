export interface UserModel extends Omit<UserResponseModel, 'createdAt' | 'updatedAt' | 'isAdmin'> {
  applicationList: string[];
  createdAt: Date;
}

export interface ApplicationUserModel extends UserResponseModel {
  associatedAt: Date;
}

export interface CreateUserModel {
  name: string;
  emailAddress: string;
  applicationList: string[];
}

export type EditUserModel = Omit<CreateUserModel, 'emailAddress'>;

export interface UserResponseModel {
  id: string;
  isAdmin: boolean;
  emailAddress: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

<app-input-group
  idField="{{ idField }}Input"
  [error]="control.errors"
  [validControl]="(control.touched && control.valid) || !control.touched"
  [controlHasValue]="control.touched && !!control.value"
>
  <ng-container *ngTemplateOutlet="inputTemplate" focus-container></ng-container>
</app-input-group>

<ng-template #inputTemplate>
  <div class="h-100 w-100">
    <input
      type="file"
      class="d-none"
      accept="image/*"
      (select)="handleFileSelect($event, inputFile)"
      (cancel)="updateValidity()"
      [multiple]="false"
      [formControl]="control"
      #inputFile
    />
    <button
      [id]="idField + 'Button'"
      type="button"
      class="w-100 h-100 d-flex align-items-center input-control"
      [ngClass]="isReadonly ? 'text-gray-500' : 'text-primary'"
      (click)="handleButtonClick(inputFile)"
      (blur)="this.blurEvent.emit()"
      (click)="clickEvent.emit()"
      (focus)="focusEvent.emit()"
      [disabled]="isReadonly"
    >
      <i class="fas fa-camera"></i>
      <span
        class="ml-4 font-weight-normal"
        [ngClass]="isReadonly ? 'text-gray-500' : 'text-dark'"
        [innerText]="control.value ? decodeUri(control.value.name) : 'Escolher imagem'"
      ></span>
    </button>
  </div>
</ng-template>

export interface LoadingModel {
  /** Activate loading. */
  show: () => void;
  /** Deactivate loading. */
  hide: () => void;
  /** Toggle loading. */
  toggle: () => void;
  /** Is loading visible? */
  visible: boolean;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DefaultErrorMessages, execIfFunction } from '@core/utilities';
import { AlertHookType, AlertModel, AlertSize, AlertType } from '@models';
import { AlertService } from '@services';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() alertId = '';
  @Input() size: AlertSize = AlertSize.Small;

  private destroy$ = new Subject<void>();
  private alertTimeout?: ReturnType<typeof setTimeout>;
  private onShowGlobal?: () => void;
  private onHideGlobal?: () => void;
  private onHide?: () => void;

  /** Leading part of the error description (before the "try again" button-link). */
  message: string;
  /** "Try again" button-link. */
  linkedMessage?: string;
  /** Trailing part of the error description (after the "try again" button-link). */
  trailingMessage?: string;
  /**
   * Observable that'll be subscribed to if user clicks the "try again"
   * button. You must take care of unsubscription, this component won't do it.
   */
  linkObservable?: Observable<any>;

  visible?: boolean;
  title?: string;
  type?: AlertType;

  constructor(private readonly alertService: AlertService) {}

  ngOnInit(): void {
    this.alertService.alert$
      .pipe(
        takeUntil(this.destroy$),
        filter(alert => alert.id === this.alertId),
        tap(alert => {
          this.title = alert.title;
          this.type = alert.type;
          this.size = alert.size ?? this.size;
          this.onHide = alert.onHide;
          this.message = alert.message;
          this.linkedMessage = alert.linkedMessage;
          this.trailingMessage = alert.trailingMessage;
          this.linkObservable = execIfFunction(alert.linkObservable);

          // if the default error message is passed along with
          // an observable, link it to the "try again" text
          if (this.message === DefaultErrorMessages.AlertGeneric && !this.linkedMessage && !this.trailingMessage && this.linkObservable) {
            this.linkedMessage = 'Tente novamente';
            [this.message, this.trailingMessage] = this.message.split(this.linkedMessage);
          }

          this.visible = true;

          this.execOnShow(alert);
          if (this.alertTimeout) {
            clearTimeout(this.alertTimeout);
          }

          this.alertTimeout = setTimeout(() => {
            this.visible = false;
            this.execOnHide();
          }, alert.timeout ?? 4000);
        }),
      )
      .subscribe();

    this.alertService.hide$
      .pipe(
        takeUntil(this.destroy$),
        filter(alertId => alertId === this.alertId),
        tap(this.close.bind(this)),
      )
      .subscribe();

    this.alertService.setGlobalHook$
      .pipe(
        takeUntil(this.destroy$),
        filter(hook => hook.id === this.alertId),
        tap(hook => {
          switch (hook.type) {
            case AlertHookType.OnHide:
              this.onHideGlobal = hook.action;
              break;

            case AlertHookType.OnShow:
              this.onShowGlobal = hook.action;
              break;

            default:
              break;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    if (this.alertTimeout) {
      clearTimeout(this.alertTimeout);
    }
    this.execOnHide();
    this.visible = false;
  }

  private execOnShow(alert: AlertModel) {
    if (this.onShowGlobal) {
      this.onShowGlobal();
    }
    if (alert.onShow) {
      alert.onShow();
    }
  }

  private execOnHide() {
    if (this.onHideGlobal) {
      this.onHideGlobal();
    }
    if (this.onHide) {
      this.onHide();
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordFieldComponent } from './password-field.component';
import { InputGroupModule } from '@forms/input-group/input-group.module';
import { StringFieldModule } from '@forms/string-field/string-field.module';

@NgModule({
  declarations: [PasswordFieldComponent],
  imports: [CommonModule, InputGroupModule, StringFieldModule],
  exports: [PasswordFieldComponent],
})
export class PasswordFieldModule {}

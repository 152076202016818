import { Component } from '@angular/core';
import { FieldCommonComponent } from '@core/classes';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
})
export class ImageFieldComponent extends FieldCommonComponent {
  handleFileSelect(files: File[], element: any) {
    this.control.setValue(files[0]);

    // enable the uploading of a file just deleted
    element.value = '';
  }

  handleButtonClick(element: any) {
    element.click();
  }

  updateValidity() {
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }

  decodeUri(fileName: string) {
    return decodeURI(fileName);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiNotifitoken, ApplicationEndpoints, userSelectorQuery } from '@core/utilities';
import {
  ApplicationModel,
  ApplicationUserManagementRequestBodyModel,
  CreateApplicationModel,
  EditApplicationModel,
  ResponseModel,
} from '@models';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationsService {
  constructor(private readonly http: HttpClient) {}

  getApplicationList(userId = ''): Observable<ResponseModel<ApplicationModel[]>> {
    return this.http.get<ResponseModel<ApplicationModel[]>>(apiNotifitoken(ApplicationEndpoints.MultipleApplications), {
      params: { [userSelectorQuery]: userId },
    });
  }

  getApplicationDetails(id: string): Observable<ResponseModel<ApplicationModel>> {
    return this.http.get<ResponseModel<ApplicationModel>>(apiNotifitoken(ApplicationEndpoints.SingleApplication, id));
  }

  deleteApplication(id: string): Observable<ResponseModel<void>> {
    return this.http.delete<ResponseModel<void>>(apiNotifitoken(ApplicationEndpoints.SingleApplication, id));
  }

  refreshApiKey(id: string): Observable<ResponseModel<ApplicationModel>> {
    return this.http.patch<ResponseModel<ApplicationModel>>(apiNotifitoken(ApplicationEndpoints.RefreshApiKey, id), {});
  }

  editApplication(id: string, name: string): Observable<ResponseModel<ApplicationModel>> {
    const body: EditApplicationModel = {
      name,
    };

    return this.http.patch<ResponseModel<ApplicationModel>>(apiNotifitoken(ApplicationEndpoints.SingleApplication, id), body);
  }

  createApplication(body: CreateApplicationModel): Observable<ResponseModel<ApplicationModel>> {
    return this.http.post<ResponseModel<ApplicationModel>>(apiNotifitoken(ApplicationEndpoints.MultipleApplications), body);
  }

  detachUsers(id: string, userIds: string[]): Observable<ResponseModel<void>> {
    const body: ApplicationUserManagementRequestBodyModel = {
      userIds,
    };

    return this.http.patch<ResponseModel<void>>(apiNotifitoken(ApplicationEndpoints.DetachUser, id), body);
  }

  attachUsers(id: string, userIds: string[]): Observable<ResponseModel<void>> {
    const body: ApplicationUserManagementRequestBodyModel = {
      userIds,
    };

    return this.http.patch<ResponseModel<void>>(apiNotifitoken(ApplicationEndpoints.AttachUser, id), body);
  }
}

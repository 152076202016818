import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

export interface GenericTableHeader {
  text: string;
  width?: string;
  dynamicColumn?: boolean;
  textCenter?: boolean;
}

export const enum GenericTableCellStatus {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}

export interface GenericTableCell {
  text: string;
  textClass?: string;
  subText?: string;
  subTextClass?: string;
  truncateText?: boolean;
  textCenter?: boolean;
  status?: GenericTableCellStatus;
}

export interface GenericUnidentifiableTableRow {
  cells: GenericTableCell[];
  textClass?: string;
  double?: boolean;
}

export interface GenericIdentifiableTableRow {
  cells: GenericTableCell[];
  textClass?: string;
  double?: boolean;

  /** This row's element's id - needed to interact with an API. */
  id: string;
}

export type GenericTableRow = GenericUnidentifiableTableRow | GenericIdentifiableTableRow;

/** A button that appears besides the table title. */
export interface GenericTableButton {
  /** Accepts HTML. */
  text: string;
  /** Icon class. */
  icon?: string;
  /** Function to be executed when the button is clicked. */
  action: () => any;
  /** Should the button be styled as secondary instead of primary. */
  secondary?: boolean;
  /** Should the button be disabled. */
  disabled?: boolean;
  /** Attribute for internal use - combination of `text` and `icon`. */
  __innerHtml?: string;
}

/** A button that only appear when there are row selected thrugh the checkbox. */
export interface GenericTableSelectButton {
  /** Accepts HTML. */
  text: string;
  /** Icon class. */
  icon?: string;
  /** Function to be executed when the button is clicked. */
  action: (selected: GenericTableRow[]) => any;
  /** Attribute for internal use - combination of `text` and `icon`. */
  __innerHtml?: string;
}

export interface GenericTableRowAction {
  icon: string;
  action: (row: GenericTableRow, btnElement?: HTMLButtonElement) => any;
  visible?: (row: GenericTableRow) => boolean;
  title?: string;

  /** Default to no tooltip. */
  tooltipMessage?: string | ((row: GenericTableRow, btnElement: HTMLButtonElement) => string);
  /** Default to top. */
  tooltipPlacement?: AvailbleBSPositions | ((row: GenericTableRow, btnElement: HTMLButtonElement) => AvailbleBSPositions);
  /** Default to show on hover. */
  tooltipTriggers?: string | ((row: GenericTableRow, btnElement: HTMLButtonElement) => string);
}

import { ApplicationEndpoints, EmailEndpoints, UserEndpoints } from '@core/utilities/endpoints';
import { environment } from '@environment';

/** Interpolate parameters 'params[i]' into the endpoint's '{{i}}' if they exist. */
export const endpointInterpolation = (baseUrl: string, endpoint: string, ...params: Array<string | number>) => {
  const interpolatedEndpoint = params.reduce<string>((acc, cur, i) => acc.split(`{{${i}}}`).join(cur.toString()), endpoint);

  return baseUrl + interpolatedEndpoint;
};

export type NotifitokenEndpoint = ApplicationEndpoints | EmailEndpoints | UserEndpoints;

export const apiNotifitoken = (endpoint: NotifitokenEndpoint, ...params: Array<string | number>): string =>
  endpointInterpolation(environment.backendUrl, endpoint, ...params);

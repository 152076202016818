import { Component, Input, OnInit } from '@angular/core';
import { CheckboxOptionModel } from '@models';
import { FormArray } from '@ng-stack/forms';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent implements OnInit {
  @Input() options: CheckboxOptionModel[];
  @Input() formArray: FormArray<boolean>;
  @Input() small = false;
  @Input() emptyText = 'Sua lista está vazia';
  @Input() emptySubText = '';
  @Input() notFoundText = 'Item não encontrado';
  @Input() notFoundSubText = 'Por favor, tente buscar por outro nome.';
  @Input() searchText = 'Busque por itens';

  isEmpty: boolean;
  visibleOptions: CheckboxOptionModel[] = [];

  ngOnInit() {
    this.isEmpty = !this.options.length;
  }

  extractTextFn(item: CheckboxOptionModel) {
    return item.name;
  }
}

import { AbstractControl, ValidationErrors } from '@angular/forms';

export class EqualToValidator {
  /**
   * EqualToValidator.validator
   *
   * Guarantee that a couple of fields are equal to one another (email, password). The validation occurs every time either field is edited.
   *
   * @param equalToControl - the field that this must equal.
   * @param fieldType - `equalToControl`'s type - if it's 'confirm' it'll be validated every time this one is edited.
   *
   * @example
   * ```ts
   * form = new FormGroup({
   *   email: new FormControl(null, [Validators.required, Validators.email, EqualToValidator.validator('emailConfirm', 'confirm')]),
   *   emailConfirm: new FormControl(null, [Validators.required, EqualToValidator.validator('email', 'email')]),
   *   password: new FormControl(null, [Validators.required, Validators.password, EqualToValidator.validator('passwordConfirm', 'confirm')]),
   *   passwordConfirm: new FormControl(null, [Validators.required, EqualToValidator.validator('password', 'password')]),
   * });
   * ```
   */
  static validator(equalToControl: AbstractControl | string, fieldType: 'password' | 'email' | 'confirm') {
    return (control: AbstractControl): ValidationErrors => {
      if (typeof equalToControl === 'string') {
        const tmp = control.parent?.get(equalToControl);

        // `control.parent` is null during page init
        if (!tmp) {
          return {};
        }

        equalToControl = tmp;
      }

      if (fieldType === 'email' && control.value !== equalToControl.value) {
        return { equalToEmail: true };
      }
      if (fieldType === 'password' && control.value !== equalToControl.value) {
        return { equalToPassword: true };
      }

      if (fieldType === 'confirm') {
        equalToControl.updateValueAndValidity();
      }

      return {};
    };
  }
}

<app-input-group
  *ngIf="!clean; else inputTemplate"
  idField="{{ idField }}Input"
  [error]="control.errors"
  [validControl]="(control.touched && control.valid) || !control.touched"
  [label]="label"
  [isReadonly]="isReadonly"
  [controlHasValue]="control.value"
>
  <ng-container *ngTemplateOutlet="inputTemplate" focus-container></ng-container>
</app-input-group>

<ng-template #inputTemplate>
  <input
    class="input-control w-100"
    id="{{ idField }}Input"
    name="{{ idField }}InputName"
    [formControl]="control"
    [maxlength]="maxLength"
    [minlength]="minLength"
    [placeholder]="placeholder"
    [readonly]="isReadonly"
    [type]="type"
    [style]="clean ? { border: 'none', outline: 'none' } : {}"
    (blur)="this.blurEvent.emit()"
    (click)="clickEvent.emit()"
    (focus)="focusEvent.emit()"
    (keypress)="validateNumberInput($event)"
  />
</ng-template>

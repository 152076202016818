import { Directive, EventEmitter, Input, Output } from '@angular/core';

/** Abstract class to be extended by any modal component. */
@Directive()
export abstract class CommonModalComponent {
  // two-way data binding
  /** Determines if the modal should be visible. */
  @Input() public get visible() {
    return this._visible;
  }
  /** Emits a new value every time the modal visibility changes. */
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(value);
  }

  /** The modal's id. */
  @Input() public idField = '';
  /** Should the main button be disabled? */
  @Input() public mainButtonDisabled = false;
  /** Function to be executed when the user presses the main button. */
  @Input() public mainButtonText: string;
  /** Should a close (X) button be shown in the header? */
  @Input() public showCloseButton = true;
  /** Modal title. */
  @Input() public title?: string;
  /** Should the modal close after the user presses the main button? */
  @Input() public autoCloseOnMainButton = true;

  /** Emits an event when the user presses the main button. */
  @Output() public mainButtonClick: EventEmitter<void> = new EventEmitter<void>();
  /** Emits an event just before the modal is hidden. */
  @Output() public modalHide: EventEmitter<void> = new EventEmitter<void>();
  /** Emits an event just after the modal is hidden. */
  @Output() public modalHidden: EventEmitter<void> = new EventEmitter<void>();

  private _visible = false;

  closeModal(): void {
    this.modalHide.emit();
    this.visible = false;
    this.modalHidden.emit();
  }

  handleMainButton(): void {
    if (this.autoCloseOnMainButton) {
      this.closeModal();
    }
    this.mainButtonClick.emit();
  }
}

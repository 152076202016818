import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Loading spinner that can play a result (success x error) animation when loading finishes.
 * After the animation finishes playing, the component's reset and the loading spinner comes back.
 *
 * @example
 * ```typescript
 * initGenerateApiKeyBtnAnimation$ = new Subject<boolean>();
 *
 * refreshAuthKey(): void {
 *   this.generateApiKeyBtnAnimation = true;
 *   this.service.genAuthKey().pipe(tap(result => {
 *     this.initGenerateApiKeyBtnAnimation$.next(result);
 *   })).subscribe()
 * }
 * ```
 *
 * @example
 * ```html
 * <button (click)="refreshAuthKey()">
 *   <i class="fal fa-sync" *ngIf="!generateApiKeyBtnAnimation; else animation"></i>
 *
 *   <ng-template #animation>
 *     <app-spinner-result
 *       [success$]="initGenerateApiKeyBtnAnimation$.asObservable()"
 *       [strokeWidth]="7"
 *       [width]="30"
 *       [height]="30"
 *       (finished)="generateApiKeyBtnAnimation = false"
 *     ></app-spinner-result>
 *   </ng-template>
 * </button>
 * ```
 */
@Component({
  selector: 'app-spinner-result',
  templateUrl: './spinner-result.component.html',
  styleUrls: ['./spinner-result.component.scss'],
})
export class SpinnerResultComponent implements OnInit {
  /** The element's width. */
  @Input() width = 80;
  /** The element's height. */
  @Input() height = 80;
  /** The spinner's thickness. */
  @Input() strokeWidth = 5;
  /**
   * An observable that'll emit a value everytime the result animation should be started.
   * This value represents the success result, so `true` means success and `false` means error.
   */
  @Input() success$: Observable<boolean>;
  /** An event will be emitted everytime the result animation has finished playing. */
  @Output() finished = new EventEmitter<void>();

  /** SVG path for success icon (V checkmark). */
  successPath = 'M 14.1 27.2 l 7.1 7.2 16.7-16.8';
  /** SVG path for error icon (X checkmark). */
  errorPath = 'M 16 16 36 36 M 36 16 16 36';
  /** Is loading? When the loading ends, the result animation will be played.*/
  loading = true;
  /** Should the success animation be played (as opposed to the error one)? */
  succeeded = true;

  ngOnInit(): void {
    this.success$.subscribe(succeeded => {
      this.succeeded = succeeded;
      this.loading = false;

      setTimeout(() => {
        this.finished.emit();
        this.loading = true;
      }, 2500);
    });
  }
}

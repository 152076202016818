import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BuildRibbonComponent } from './build-ribbon.component';

@NgModule({
  declarations: [BuildRibbonComponent],
  imports: [CommonModule],
  exports: [BuildRibbonComponent],
})
export class BuildRibbonModule {}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultErrorMessages, RequestObject } from '@core/utilities';
import { AlertType } from '@models';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../alert';
import { AuthService } from '../auth';
import { GlobalSpinnerService } from '../global-spinner';

@Injectable()
export class ForgotPasswordService {
  private __step = 0;

  email: string;
  challengeCode: string;

  constructor(
    private readonly alertService: AlertService,
    private readonly authService: AuthService,
    private readonly globalSpinnerService: GlobalSpinnerService,
    private readonly router: Router,
  ) {}

  sendRecoveryEmail(email?: string): RequestObject<void> {
    if (email) {
      this.email = email;
    }

    const requestObject = RequestObject.raw<void>(this.authService.startForgotPasswordChallenge(this.email), {
      requestType: 'PATCH',
      loading: this.globalSpinnerService,
    });

    this.pipeAlert(requestObject);

    return requestObject;
  }

  setNewPassword(password: string): RequestObject<void> {
    const requestObject = RequestObject.raw<void>(this.authService.completeForgotPasswordChallenge(this.challengeCode, password), {
      loading: this.globalSpinnerService,
      requestType: 'PATCH',
    });

    this.pipeAlert(requestObject);

    return requestObject;
  }

  get step(): number {
    return this.__step;
  }

  nextStep() {
    this.changeStep(this.__step < 2, 1);
  }

  prevStep() {
    this.changeStep(this.__step > 0, -1);
  }

  reset() {
    this.__step = 0;
    this.email = '';
    this.challengeCode = '';
  }

  private changeStep(condition: boolean, offset: number) {
    if (condition) {
      this.__step += offset;
    } else {
      this.router.navigate(['/login']);
    }
  }

  private pipeAlert(requestObject: RequestObject<any>): void {
    requestObject.observable = requestObject.observable.pipe(
      catchError(err => {
        let errorMsg: string;

        switch (err.code) {
          case 'CodeMismatchException':
            errorMsg = 'O código de autenticação está incorreto, tente novamente.';
            break;

          case 'LimitExceededException':

          case 'TooManyRequestsException':
            errorMsg = DefaultErrorMessages.TooManyRequests;
            break;

          default:
            errorMsg = DefaultErrorMessages.AlertGeneric;
        }

        this.alertService.show({
          id: 'alert-login',
          type: AlertType.Error,
          message: errorMsg,
        });

        return throwError(err);
      }),
    );
  }
}

import { ModalDataModel } from '@models';

export enum UserSettingsModalType {
  Create,
  Edit,
  DeleteSingle,
  DeleteMultiple,
}

export type UserSettingsModalDataModel = ModalDataModel<UserSettingsModalType>;

export interface UserSettingsFormModel {
  name: string;
  emailAddress: string;
  applications: boolean[];
}

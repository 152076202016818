import { ModalDataModel } from './modal.model';

export interface ApplicationModel {
  id: string;
  apiKey: string;
  name: string;
}

export interface CreateApplicationModel {
  name: string;
  emailAddresses: string[];
}

export type EditApplicationModel = Pick<ApplicationModel, 'name'>;

export enum ApplicationUserModalType {
  Attach,
  DetachSingle,
  DetachMultiple,
}

export enum ApplicationSettingsModalType {
  Delete,
  RefreshApiKey,
}

export type ApplicationUserModalDataModel = ModalDataModel<ApplicationUserModalType>;

export type ApplicationSettingsModalDataModel = ModalDataModel<ApplicationSettingsModalType>;

export interface ApplicationUserManagementRequestBodyModel {
  userIds: string[];
}

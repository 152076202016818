import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import { ValidatorFn } from '@ng-stack/forms';

export class ArrayValidator {
  /**
   * ArrayValidator.validator
   *
   * Applies validators to each field of a FormArray.
   *
   * @example
   * ```ts
   * newApplicationForm = new FormGroup<any>({
   *   image: new FormControl(null, [FileRequiredValidator.validator]),
   *   name: new FormControl<string>(null, [Validators.required]),
   *   emails: new FormArray<string>([], [Validators.required, ArrayValidator.validator(Validators.email)]),
   * });
   * ```
   */
  static validator(validators: ValidatorFn | ValidatorFn[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      (control as FormArray).controls.forEach((field: AbstractControl) => {
        field.setValidators(validators);
        field.markAsDirty();
        field.markAsTouched();

        setTimeout(() => {
          if (!field.valid) {
            control.setErrors(field.errors);
          }
        });
      });

      return {};
    };
  }
}

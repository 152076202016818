import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FieldCommonComponent } from '@core/classes';
import { TagsInputFieldType } from '@models';
import { FormControl } from '@ng-stack/forms';
import { ReplaySubject, Subscription } from 'rxjs';
import { skipWhile, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tags-field',
  templateUrl: './tags-field.component.html',
  styleUrls: ['./tags-field.component.scss'],
})
export class TagsFieldComponent extends FieldCommonComponent implements OnInit, OnDestroy {
  @ViewChild('tagsField') tagsContainerElement: ElementRef;
  @ViewChild('actualInput') textInputElement: ElementRef;

  @Input() array: FormArray;
  @Input() type: TagsInputFieldType = 'text';
  @Input() placeholder = '';
  @Input() clean?: boolean;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  private backspaceTimeout: ReturnType<typeof setTimeout>;

  activeTag = -1;
  control = new FormControl<string>(null);
  scrollbarVisible = false;
  inputFocused = false;

  ngOnInit(): void {
    // show errors (including "required") when the input field was touched
    this.blurEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.inputFocused = false;
      this.array.markAsTouched();
      this.saveCurrentTag();
      this.clearBackspaceTimeout();

      // `false` because we don't wanna re-focus
      // the input that was just blurred
      this.resetActiveTag(false);
    });

    // hide errrors when the input field is focused
    this.focusEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.inputFocused = true;
      this.array.markAsUntouched();
    });

    this.subscribeToFormReset();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  handleBackspace() {
    if (!this.control.value) {
      this.clearBackspaceTimeout();

      if (this.activeTag === -1) {
        this.activeTag = this.array.length - 1;
        this.backspaceTimeout = setTimeout(() => this.resetActiveTag(), 1500);
      } else {
        this.removeTagAt(this.activeTag);
        if (this.activeTag === this.array.length) {
          this.resetActiveTag();
        }
        setTimeout(() => this.scrollToCurrentTag({ inline: 'nearest' }));
      }
    }
  }

  handleGenericKeypress(e: KeyboardEvent) {
    if (this.control.value) {
      return;
    }

    const allowedKeysWhileSelected = ['Backspace', 'Enter', 'ArrowLeft', 'ArrowRight', 'Control'];

    if (!allowedKeysWhileSelected.includes(e.key) && !(e.key === 'c' && e.ctrlKey)) {
      this.clearBackspaceTimeout();
      this.resetActiveTag();
    }

    if (e.ctrlKey && !e.altKey && !e.metaKey) {
      this.handleControlKeypress(e);
    }
    if (!e.ctrlKey && (e.altKey || e.metaKey)) {
      this.handleAltKeypress(e);
    }
  }

  handleArrowKeypress(direction: 'right' | 'left') {
    if (!this.control.value) {
      switch (direction) {
        case 'left':
          if (this.activeTag === -1) {
            this.activeTag = this.array.length - 1;
          } else if (this.activeTag !== 0) {
            this.activeTag = this.activeTag - 1;
          }
          break;

        case 'right':
          if (this.activeTag === this.array.length - 1) {
            this.resetActiveTag();
          } else if (this.activeTag !== -1) {
            this.activeTag = this.activeTag + 1;
          }
          break;

        default:
          break;
      }

      this.scrollToCurrentTag({ inline: direction === 'left' ? 'nearest' : 'end' });
    }
  }

  handleCopy() {
    if (!this.control.value) {
      if (this.isActiveTagValid()) {
        navigator.clipboard.writeText(this.array.value.at(this.activeTag));
      } else {
        navigator.clipboard.writeText((this.array.value as string[]).join(', ') + ' ');
      }
    }
  }

  handlePaste() {
    if (this.type === 'email') {
      setTimeout(() => {
        let tmp: FormControl<string>;
        const text = this.control.value.split(/[\s\n,]/);

        // save tag for anything in the pasted text
        // that barely resembles an email address
        text.slice(0, text.length - 1).forEach(tag => {
          if (/^.*[^.]@.+\..+$/.test(tag.trim())) {
            this.array.push((tmp = new FormControl<string>(tag.trim())));
            tmp.updateValueAndValidity();
          }
        });

        this.array.updateValueAndValidity();
        this.control.setValue(text[text.length - 1]);
      });
    }
  }

  handleEnterKeypress(e: Event) {
    const value = this.control.value?.trim();

    if (value) {
      this.handleSaveTagKeypress(e);
    } else if (this.isActiveTagValid()) {
      this.editTagAt(this.activeTag, e.target);

      e.stopPropagation();
      e.preventDefault();
    }
  }

  handleSaveTagKeypress(e: Event) {
    const value = this.control.value?.trim();

    if (!value || ((e as any).key === ' ' && this.type !== 'email')) {
      return;
    }
    this.saveCurrentTag();
    (e.target as any)?.focus();

    e.stopPropagation();
    e.preventDefault();
  }

  editTagAt(i: number, input: any) {
    const value = this.array.value[i];

    this.array.removeAt(i);
    this.control.setValue(value);

    this.resetActiveTag();
    (input as HTMLInputElement).scrollIntoView({ behavior: 'smooth' });
    (input as HTMLInputElement).select();
  }

  removeTagAt(i: number) {
    this.array.removeAt(i);
  }

  checkScrollbarVisible(container: HTMLElement): void {
    const element = this.clean ? container : container.parentElement?.parentElement;

    this.scrollbarVisible = element ? element.scrollWidth > element.offsetWidth : this.array.length > 1;
  }

  isInputOverflowing(input: HTMLElement): boolean {
    return input.scrollWidth > input.offsetWidth;
  }

  isActiveTagValid() {
    return 0 <= this.activeTag && this.activeTag < this.array.length;
  }

  private scrollToCurrentTag(scrollOpts: ScrollIntoViewOptions = {}) {
    if (this.activeTag !== -1) {
      scrollOpts.behavior = scrollOpts.behavior ?? 'smooth';
      const tagElement = (this.tagsContainerElement.nativeElement as HTMLDivElement).getElementsByClassName('tag')[this.activeTag];

      tagElement?.scrollIntoView(scrollOpts);
    }
  }

  private handleControlKeypress(e: KeyboardEvent) {
    switch (e.key) {
      case 'Backspace':
        this.removeTagAt(this.array.length - 1);
        e.preventDefault();
        e.stopPropagation();
        break;

      default:
        break;
    }
  }

  private handleAltKeypress(e: KeyboardEvent) {
    switch (e.key) {
      case 'Backspace':
        this.editTagAt(this.array.length - 1, e.target);
        e.preventDefault();
        e.stopPropagation();
        break;

      default:
        break;
    }
  }

  private resetActiveTag(focus = true) {
    const input = this.textInputElement.nativeElement as HTMLInputElement;

    this.activeTag = -1;

    if (focus) {
      input.focus();
    } else {
      input.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private clearBackspaceTimeout() {
    if (this.backspaceTimeout) {
      clearTimeout(this.backspaceTimeout);
    }
  }

  private saveCurrentTag() {
    const value = this.control.value?.trim();

    if (value) {
      const control = new FormControl<string>(value);

      this.array.push(control);
      control.updateValueAndValidity();
      this.array.updateValueAndValidity();
      this.control.reset();
    }
  }

  /**
   * If the FormGroup above the FormArray is `.reset()`, in which case
   * it'll set all of the FormArray's FormControls to value null. Because
   * of this, the FormArray should be cleared and updated.
   *
   * This method takes care of that.
   */
  private subscribeToFormReset(): Subscription {
    return this.array.valueChanges
      .pipe(
        skipWhile((v: any[]) => !v.length || v[0] !== null),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.array.clear();
        this.array.markAsUntouched();
        this.subscribeToFormReset();
      });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputGroupComponent } from './input-group.component';
import { ErrorMessageModule } from '@forms/error-message/error-message.module';

@NgModule({
  declarations: [InputGroupComponent],
  imports: [CommonModule, ErrorMessageModule],
  exports: [InputGroupComponent],
})
export class InputGroupModule {}

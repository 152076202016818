import { Component, Input } from '@angular/core';
import { execIfFunction, ValidatorsErrorMessage } from '@core/utilities';
import { ValidationErrors } from '@ng-stack/forms';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent {
  /**
   * `<label>`'s `for` attribute, link the error with the form field.
   * **Must** be the form field's `id`.
   */
  @Input() for = '';

  /** `FormControl`'s error. */
  @Input() set error(newError: ValidationErrors) {
    if (newError) {
      this.errorKey = Object.keys(newError)[0];
      this.errorObject = newError[this.errorKey];
    }

    this._error = newError;
  }

  private _error: ValidationErrors;

  errorKey: string;
  errorObject: any;

  get error() {
    return this._error;
  }

  get errorMessage() {
    return execIfFunction(ValidatorsErrorMessage[this.errorKey], this.errorObject);
  }
}

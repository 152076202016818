import { Observable } from 'rxjs';

export const enum AlertType {
  Success = 'success',
  Error = 'error',
}

export enum AlertSize {
  Small = 'alert-sm',
  Medium = 'alert-md',
  Large = 'alert-lg',
  FitContent = 'alert-fit-content',
}

export interface AlertModel {
  id: string;
  type: AlertType;
  title?: string;
  onShow?: () => void;
  onHide?: () => void;
  size?: AlertSize;

  /** Leading part of the error description (before the "try again" button-link). */
  message: string;
  /** "Try again" button-link. */
  linkedMessage?: string;
  /** Trailing part of the error description (after the "try again" button-link). */
  trailingMessage?: string;
  /**
   * Observable that'll be subscribed to if user clicks the "try again"
   * button. You must take care of unsubscription, this component won't do it.
   */
  linkObservable?: Observable<any> | (() => Observable<any>);
  /** In ms, default 4000. */
  timeout?: number;
}

export const enum AlertHookType {
  OnShow,
  OnHide,
}

export interface AlertHookModel {
  id: string;
  type: AlertHookType;
  action?: () => void;
}

/* eslint @typescript-eslint/no-non-null-assertion: 0 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiNotifitoken, UserEndpoints } from '@core/utilities';
import { CognitoAccessGroups, CognitoUserAttributes, ResponseModel, UserResponseModel } from '@models';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth';

@Injectable()
export class UserService {
  private __id: string;
  private __name: string;
  private __email: string;
  private __admin: boolean;

  constructor(private readonly authService: AuthService, private readonly http: HttpClient) {}

  get name(): string {
    return this.__name;
  }

  get email(): string {
    return this.__email;
  }

  get id(): string {
    return this.__id;
  }

  isAdmin(): boolean {
    return this.__admin;
  }

  /**
   * Fetch current logged user's data from Cognito and Notifitoken API and save it in memory.
   * @param overwrite should the data be re-fetched even if there's already data in memory?
   * @returns didFetch was the data actually fetched (as opposed to recovered from memory)
   */
  fetchData(overwrite = false): Observable<ResponseModel<boolean>> {
    if (!overwrite && this.__name) {
      const return$ = new Subject<ResponseModel<boolean>>();

      setTimeout(() => {
        return$.next({ data: false });
        return$.complete();
      });

      return return$.asObservable();
    }

    const { user, accessToken } = this.authService;

    if (!user || !accessToken) {
      return throwError('User is not authenticated.');
    }

    const groups: string[] = accessToken.payload['cognito:groups'] ?? [];

    this.__id = user.getUsername();
    this.__admin = groups.includes(CognitoAccessGroups.Admin);

    return this.authService.getSession(user).pipe(
      take(1),
      tap(() => {
        user.getUserAttributes((err, res) => {
          if (err) {
            console.log(err.message || JSON.stringify(err));
            throwError(err);
          } else {
            this.__email = res!.find(attr => attr.getName() === CognitoUserAttributes.Email)!.getValue();
          }
        });
      }),
      mergeMap(() => this.fetchUserName(this.__id)),
      tap(name => (this.__name = name)),
      map(() => ({ data: true })),
      catchError(err => {
        this.authService.signOut();

        return throwError(err);
      }),
    );
  }

  private fetchUserName(id: string): Observable<string> {
    return this.http
      .get<ResponseModel<UserResponseModel>>(apiNotifitoken(UserEndpoints.SingleUser, id))
      .pipe(map((response: ResponseModel<UserResponseModel>) => response.data.name));
  }
}

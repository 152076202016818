import { Subject } from 'rxjs';

export interface TabModel {
  title: string;
  url: string;
}

export interface TabUtilsOptsModel {
  afterSelect?: () => void;
  takeUntilSubject?: Subject<any>;
}

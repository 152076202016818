import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { MODAL_BACKDROP_TAGNAME } from '@core/utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-body',
  templateUrl: './modal-body.component.html',
})
export class ModalBodyComponent implements OnDestroy, AfterViewInit {
  @ViewChild('modal') private modal!: TemplateRef<any>;

  /** Emit a new event just before the modal is hidden. */
  @Output() hide: EventEmitter<void> = new EventEmitter<void>();
  /** Emit a new event just after the modal is hidden. */
  @Output() hidden: EventEmitter<void> = new EventEmitter<void>();
  /** Emit a new value every time the modal visibility changes. */
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Classes that'll be passed to the modal. */
  @Input() classes = '';
  /** Prevent the user from closing the modal. */
  @Input() lock?: boolean;

  private _visible: boolean;
  private modalRef?: BsModalRef;
  private onHideSubscription?: Subscription;
  private onHiddenSubscription?: Subscription;

  constructor(private modalService: BsModalService) {}

  /** Function to close the modal. */
  @Input() closeFn: () => void = () => (this.visible = false);
  /** Determine if the modal should be visible. */
  @Input() set visible(visible: boolean) {
    if (this._visible !== undefined) {
      visible ? this.open() : this.close();
    }

    this._visible = visible;
    this.visibleChange.emit(visible);
  }
  get visible() {
    return this._visible;
  }

  @HostListener('window:click', ['$event.target.tagName'])
  onWindowClick(target: string) {
    if (target === MODAL_BACKDROP_TAGNAME) {
      this.close();
    }
  }

  @HostListener('window:keydown.escape', [])
  onEscPress() {
    this.close();
  }

  ngAfterViewInit(): void {
    if (this.visible) {
      this.open();
    }
  }

  ngOnDestroy(): void {
    this.onHideSubscription?.unsubscribe();
    this.onHiddenSubscription?.unsubscribe();
  }

  open(): void {
    this.onHideSubscription?.unsubscribe();
    this.onHiddenSubscription?.unsubscribe();

    setTimeout(() => {
      this.modalRef = this.modalService.show(this.modal, {
        class: `${this.classes} modal-dialog-centered`,
        keyboard: false,
        ignoreBackdropClick: true,
      });

      this.onHideSubscription = this.modalRef?.onHide?.subscribe(() => {
        this.closeFn();
        this.hide.emit();
      });
      this.onHiddenSubscription = this.modalRef?.onHidden?.subscribe(() => {
        this.hidden.emit();
      });
    });
  }

  close(): void {
    if (this.visible && !this.lock) {
      this.modalRef?.hide();
    }
  }
}

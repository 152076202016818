import { EventEmitter, Injectable } from '@angular/core';

/**
 * Este serviço é responsável por cuidar do botão voltar presente nas rotas filhas do login.
 * Handle the "back" button present in the '/login' children routes.
 *
 * The "back" button only emits this service's `backClickEvent`. Each children
 * should `subscribe` to the emitter and execute the desired behavior.
 */
@Injectable()
export class LoginBackHandlerService {
  private _showButton: boolean;

  /** Emits every time the button is clicked - useful to attribute action to it. */
  readonly backClickEvent = new EventEmitter<void>();

  /** Should the button be visible or not. */
  get showButton() {
    return this._showButton;
  }

  set showButton(newValue: boolean) {
    // prevent NG0100
    setTimeout(() => (this._showButton = newValue));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiNotifitoken, applicationSelectorQuery, EmailEndpoints } from '@core/utilities';
import { CreateEmailAddressBodyModel, EmailAddressResponseModel, ResponseModel } from '@models';
import { Observable } from 'rxjs';

@Injectable()
export class EmailAddressService {
  constructor(private readonly http: HttpClient) {}

  createEmail(body: CreateEmailAddressBodyModel): Observable<ResponseModel<EmailAddressResponseModel>> {
    return this.http.post<ResponseModel<EmailAddressResponseModel>>(apiNotifitoken(EmailEndpoints.MultipleEmails), body);
  }

  deleteEmail(address: string): Observable<ResponseModel<void>> {
    return this.http.delete<ResponseModel<void>>(apiNotifitoken(EmailEndpoints.SingleEmail, address));
  }

  getEmails(applicationId: string): Observable<ResponseModel<EmailAddressResponseModel[]>> {
    return this.http.get<ResponseModel<EmailAddressResponseModel[]>>(apiNotifitoken(EmailEndpoints.MultipleEmails), {
      params: { [applicationSelectorQuery]: applicationId },
    });
  }
}

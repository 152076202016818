import { LoadingModel } from '@models';

export type RequestType = 'GET' | 'POST' | 'PATCH' | 'DELETE';

export type RequestObjectOptionsModel = {
  requestType?: RequestType;
  loading?: LoadingModel;
  /** Should `take(1)` be added to the observable? Default true. */
  take1?: boolean;
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidateSubmitDirective } from './validate-submit.directive';

@NgModule({
  declarations: [ValidateSubmitDirective],
  imports: [CommonModule],
  exports: [ValidateSubmitDirective],
})
export class ValidateSubmitModule {}

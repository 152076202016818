<div *ngIf="!qntItems" class="flex-center flex-column text-center mx-auto w-50">
  <img
    class="mb-5"
    alt="Ícone de falta de dados."
    *ngIf="isAlwaysEmpty ? emptyListIcon : notFoundIcon"
    [src]="isAlwaysEmpty ? emptyListIcon : notFoundIcon"
  />
  <h5
    class="{{ isAlwaysEmpty ? 'font-weight-normal' : 'font-weight-light' }} mb-12px"
    [innerHtml]="(isAlwaysEmpty ? emptyListMessage : notFoundMessage) | safeHtml"
  ></h5>
  <span class="font-weight-light">{{ isAlwaysEmpty ? emptyListSubMessage : notFoundSubMessage }}</span>
  <button
    *ngIf="emptyListBtnInnerHtml && isAlwaysEmpty"
    (click)="emptyListBtnClicked.emit()"
    [innerHtml]="emptyListBtnInnerHtml | safeHtml"
    class="btn btn-primary mt-5"
    style="font-size: 0.875rem"
  ></button>
</div>

import { Component } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-build-ribbon',
  templateUrl: './build-ribbon.component.html',
  styleUrls: ['./build-ribbon.component.scss'],
})
export class BuildRibbonComponent {
  environmentName = environment.environment;

  get visible() {
    return !environment.production && !environment.local;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgStackFormsModule } from '@ng-stack/forms';
import { StringFieldComponent } from './string-field.component';
import { InputGroupModule } from '@forms/input-group/input-group.module';

@NgModule({
  declarations: [StringFieldComponent],
  imports: [CommonModule, InputGroupModule, NgStackFormsModule],
  exports: [StringFieldComponent],
})
export class StringFieldModule {}

export interface ModalDataModel<T> {
  title: string;
  content?: string;
  mainBtn: string;
  type: T;
  confirmFn: Function;
}

export interface ModalManagerCallbacks {
  /**
   * Function to be executed before the modal's content is overwritten and it
   * is opened. The data received by the modal will be passed to this function
   * and overwritten by it's return value.
   */
  formatConfirmFnArgs?: (...args: any[]) => any;
  /**
   * Function to be executed right before the modal is opened. No data will be
   * passed to it and it should return nothing.
   */
  beforeOpen?: () => void;
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { validateRoute } from '@core/utilities';
import { AuthService } from '@services';
import { Observable } from 'rxjs';

@Injectable()
export class FirstLoginGuard implements CanLoad, CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateFirstLogin();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.validateFirstLogin();
  }

  private validateFirstLogin(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return validateRoute(this.authService.isFirstLogin, this.router, 'applications');
  }
}

<nav class="bg-primary py-5 px-2 sidebar d-flex flex-column justify-content-between">
  <div class="d-flex flex-column justify-content-center">
    <div class="user align-self-center text-center">
      <div class="picture d-flex justify-content-center align-items-center">
        <i class="fal fa-user"></i>
      </div>

      <span class="font-weight-bold">{{ userName }}</span>
    </div>

    <ul class="list-unstyled d-flex flex-column my-0">
      <ng-container *ngFor="let item of items">
        <li
          *ngIf="item.route && !item.action"
          [routerLink]="item.route"
          class="px-3 item"
          [class.active]="item.route === route.split('/')[0]"
        >
          <a class="text-decoration-none">
            <ng-container [ngTemplateOutlet]="itemContent" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </a>
        </li>

        <li *ngIf="item.action && !item.route" (click)="item.action()" class="px-3 item">
          <button class="no-btn">
            <ng-container [ngTemplateOutlet]="itemContent" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </button>
        </li>
      </ng-container>
    </ul>
  </div>

  <img class="img-fluid mb-5 mx-auto" src="assets/images/logos/light-logo.svg" alt="notify logo" />
</nav>

<ng-template #itemContent let-item="item">
  <div class="d-flex align-items-center">
    <div class="text-center">
      <i [class]="item.icon"></i>
    </div>
    {{ item.title }}
  </div>
</ng-template>

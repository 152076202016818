import { NavigationEnd, Router } from '@angular/router';
import { TabUtilsOptsModel } from '@models';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

export class TabUtils {
  private afterSelect?: () => void;
  private takeUntilSubject?: Subject<void>;
  private subscription: Subscription;

  constructor(private readonly router: Router, opts?: TabUtilsOptsModel) {
    this.afterSelect = opts?.afterSelect;
    this.takeUntilSubject = opts?.takeUntilSubject;
  }

  /**
   * Subscribe to router events and execute a function after navigation.
   * @param setter function to be executed with the new URL as parameter.
   */
  setUrl(setter: (v: string) => void): void {
    setter(this.router.url);
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        this.takeUntilSubject ? takeUntil(this.takeUntilSubject) : tap(),
      )
      .subscribe(route => {
        setter((route as NavigationEnd).urlAfterRedirects);
      });
  }

  selectTab(url: string): void {
    this.router.navigate([url]);

    if (this.afterSelect) {
      this.afterSelect();
    }
  }

  destroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

/**
 * Validate the `FormGroup` used in `<form>`'s children on `form` submit.
 *
 * Since the validation occurs when the `submit` event is emitted, a `<button type="submit">` is necessary.
 * When the `form` is valid, `successfulSubmissionEvent` is emitted.
 *
 * This makes the `submit` event obsolete since it's emitted regardless of the `form`'s validity, so it's recommended to not use it.
 *
 * @example
 * ```html
 *  <form [appValidateSubmit]="formGroup" (successfulSubmissionEvent)="successValidation()">
 *    <input type="text" [formControl]="formGroup.controls.text" />
 *    <button type="submit">Enviar</button>
 *  </form>
 * ```
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit_event Submit MDN}
 */
@Directive({
  selector: 'form[appValidateSubmit]',
})
export class ValidateSubmitDirective {
  @Input() appValidateSubmit: FormGroup | FormControl | FormArray;

  @Output() successfulSubmissionEvent = new EventEmitter<void>();

  @HostListener('submit', ['$event', '$event.target'])
  onSubmit(): boolean {
    this.appValidateSubmit.markAllAsTouched();
    this.appValidateSubmit.markAsDirty();

    if (this.appValidateSubmit.valid) {
      this.successfulSubmissionEvent.emit();
    }

    return false;
  }
}

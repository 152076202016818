import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModalComponent } from '@core/classes';
import { disableForm, enableForm } from '@core/utilities';
import { CheckboxOptionModel } from '@models';
import { FormArray } from '@ng-stack/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-checkbox-list',
  templateUrl: './modal-checkbox-list.component.html',
})
export class ModalCheckboxListComponent extends CommonModalComponent implements OnInit, OnDestroy {
  @Input() options: CheckboxOptionModel[];
  @Input() formArray: FormArray<boolean>;
  @Input() emptyText = 'Sua lista está vazia';
  @Input() emptySubText = '';
  @Input() notFoundText = 'Item não encontrado';
  @Input() notFoundSubText = 'Por favor, tente buscar por outro nome.';
  @Input() searchText = 'Busque por itens';
  @Input() cancelButtonText = 'Cancelar';
  @Input() mainButtonText = 'Confirmar';
  @Input() atLeastOneRequired = true;

  /**
   * Show a spinner in the main button instead of the text.
   * Invalid if `minimalist`.
   *
   * The modal can't be closed if this is true. You may have to enclose your
   * "modal closing" in a `setTimeout` if both actions occur at once.
   */
  @Input() set mainButtonSpinner(mainButtonSpinner: boolean | undefined) {
    this.__mainButtonSpinner = mainButtonSpinner;
    mainButtonSpinner ? disableForm(this.formArray) : enableForm(this.formArray);
  }
  get mainButtonSpinner(): boolean | undefined {
    return this.__mainButtonSpinner;
  }

  private __mainButtonSpinner?: boolean;
  private destroy$ = new Subject<void>();
  atLeastOneSelected: boolean;

  ngOnInit(): void {
    this.atLeastOneSelected = this.formArray.value.some(v => v);

    this.formArray.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(checkboxes => {
          this.atLeastOneSelected = checkboxes.some(v => v);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

<svg viewBox="0 0 50 50" class="my-0" [style.height.rem]="height / 16" [style.width.rem]="width / 16">
  <g class="spinner">
    <circle class="fixed-path" cx="25" cy="25" r="20" fill="none" [class.completed]="!loading" [attr.stroke-width]="strokeWidth"></circle>
    <circle class="dashed-path" cx="25" cy="25" r="20" fill="none" [class.completed]="!loading" [attr.stroke-width]="strokeWidth"></circle>
  </g>
  <path
    class="result-icon"
    fill="none"
    [ngClass]="succeeded ? 'success' : 'error'"
    [attr.stroke-width]="strokeWidth - 1 || strokeWidth"
    [attr.d]="succeeded ? successPath : errorPath"
    *ngIf="!loading"
  />
</svg>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalBodyModule } from '@modal/modal-body/modal-body.module';
import { SpinnerModule } from '@shared/components/spinner';
import { ValidateSubmitModule } from '@shared/directives';
import { SafeHtmlModule } from '@shared/pipes';
import { ModalConfirmComponent } from './modal-confirm.component';

@NgModule({
  declarations: [ModalConfirmComponent],
  imports: [CommonModule, ModalBodyModule, SafeHtmlModule, SpinnerModule, ValidateSubmitModule],
  exports: [ModalConfirmComponent],
})
export class ModalConfirmModule {}

<ng-container *ngIf="allItems?.length">
  <div class="d-flex justify-content-between align-items-center px-12px py-3 full-input" [class.border]="border">
    <div class="flex-center flex-fill">
      <i class="far fa-search mr-3"></i>
      <app-string-field
        class="flex-fill search-input"
        type="text"
        [placeholder]="placeholder"
        [clean]="true"
        [control]="control"
        [idField]="idField"
        [label]="label"
        (blurEvent)="blurEvent.emit()"
        (clickEvent)="clickEvent.emit()"
        (focusEvent)="focusEvent.emit()"
      ></app-string-field>
    </div>
    <button *ngIf="control.value" class="no-btn delete-btn flex-center" (click)="resetSearch()">
      <i class="far fa-times"></i>
    </button>
  </div>
  <hr *ngIf="borderBottom" class="text-gray-800" [ngClass]="borderBottomNoMargin ? 'my-0' : 'my-12px'" />
</ng-container>

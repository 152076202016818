import { ModalDataModel, ModalManagerCallbacks } from '@models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { execIfFunction } from './function.utils';

export class ModalManager<T> {
  private __title: string;
  private __content?: string;
  private __mainBtn: string;
  private __type: T;
  private __confirmFn: Function;

  open = false;

  /**
   * Generic function to create a function to open a specific modal type with
   * given content and execute a given callback.
   *
   * @param data content to be inserted into the modal before opening
   * @param callbacks functions that may be executed at different points during modal setup and opening
   * @returns function that receives the related data and injects it into `formatConfirmFnArgs` before updating the modal content and opening it
   */
  show(data: ModalDataModel<T>, callbacks: ModalManagerCallbacks = {}) {
    return ((...confirmFnArgs: any[]) => {
      if (callbacks.formatConfirmFnArgs) {
        confirmFnArgs = [callbacks.formatConfirmFnArgs(...confirmFnArgs)];
      }

      this.__title = data.title;
      this.__content = data.content;
      this.__mainBtn = data.mainBtn;
      this.__type = data.type;
      this.__confirmFn = () => data.confirmFn(...confirmFnArgs);

      if (callbacks.beforeOpen) {
        callbacks.beforeOpen();
      }

      this.open = true;
    }).bind(this);
  }

  /**
   * Generic function to create a function subscribe to an observable and
   * pass to it the opening of a specific modal type with given content and
   * execute a given callback.
   *
   * @param observable observable to subscribe to.
   * @param data content to be inserted into the modal before opening
   * @param callbacks functions that may be executed at different points during modal setup and opening. The data returned from the observable will also be used for these.
   * @returns function that receives the related data and injects it into `formatConfirmFnArgs` before updating the modal content and opening it
   */
  fetchDataAndShowModal(
    observable: Observable<any> | ((...args: any[]) => Observable<any>),
    data: ModalDataModel<T>,
    callbacks?: ModalManagerCallbacks,
  ) {
    return (...args: any[]) => {
      execIfFunction<Observable<any>>(observable, ...args)
        .pipe(take(1))
        .subscribe(response => this.show(data, callbacks)(...args, response));
    };
  }

  get title(): string {
    return this.__title;
  }

  get content(): string | undefined {
    return this.__content;
  }

  get mainBtn(): string {
    return this.__mainBtn;
  }

  get type(): T {
    return this.__type;
  }

  get confirmFn(): Function {
    return this.__confirmFn;
  }
}

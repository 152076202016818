import { Injectable } from '@angular/core';
import { LoadingModel } from '@models';

@Injectable()
export class GlobalSpinnerService implements LoadingModel {
  visible = false;

  toggle(): void {
    this.visible = !this.visible;
  }

  show(): void {
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
  }
}

import { ModalDataModel } from '@models';

export interface EmailAddressResponseModel {
  emailAddress: string;
  applicationId: string;
  verificationStatus: EmailSesVerificationStatus;
}

export const enum EmailAddressStatus {
  Confirmed = 'Confirmado',
  Pending = 'Pendente',
}

export const enum EmailSesVerificationStatus {
  Failed = 'Failed',
  NotStarted = 'NotStarted',
  Pending = 'Pending',
  Success = 'Success',
  TemporaryFailure = 'TemporaryFailure',
}

export interface EmailIdentityModel {
  address: string;
  status: EmailAddressStatus;
}

export enum EmailAddressModalType {
  Create,
  Delete,
  RefreshStatus,
  Resend,
}

export interface CreateEmailAddressBodyModel {
  emailAddress: string;
  applicationId: string;
}

export type EmailAddressModalDataModel = ModalDataModel<EmailAddressModalType>;

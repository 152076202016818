import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { getPageData, mixButtonIconText, paginationConfig } from '@core/utilities';
import { GenericTableButton, GenericTableHeader, GenericTableRow, GenericTableRowAction, GenericTableSelectButton } from '@models';
import { FormArray, FormControl } from '@ng-stack/forms';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** Must be kept in sync with SCSS. */
const ACTIONS_PANEL_GAP = 8; // px
const BASE_FONT_SIZE = 16; // px

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent implements OnInit, OnDestroy {
  @Input() paginationConfig: PaginationInstance = paginationConfig();
  @Input() rowActions?: GenericTableRowAction[];
  @Input() tableButtons?: GenericTableButton[];
  @Input() tableSelectedButtons?: GenericTableSelectButton[];
  @Input() tableTitle = '';
  @Input() filteredSearch = true;

  @Input() notFoundMessage = 'Nenhum resultado encontrado';
  @Input() notFoundSubMessage = 'Por favor, tente buscar por outro termo.';
  @Input() emptyListIcon = 'assets/images/empty-state/lupa.svg';
  @Input() emptyListMessage = 'Não há itens a serem exibidos';
  @Input() emptyListSubMessage = '';
  @Input() emptyListBtnText = '';
  @Input() emptyListBtnIcon = '';
  @Output() emptyListBtnAction = new EventEmitter<void>();

  @Input() set header(header: GenericTableHeader[]) {
    this._header = header.filter(col => col.text !== 'Ações');

    if (this.rowActions?.length) {
      this._header.push({ text: 'Ações', dynamicColumn: true, textCenter: true });
    }
  }

  @Input() set data(data: GenericTableRow[]) {
    data.forEach(row => {
      row.double = row.double ?? row.cells.some(cell => cell.subText);
    });

    this._data = data;
    this.totalRows = data.length;
    this.paginationConfig.totalItems = data.length;

    if (this.header.some(item => item.dynamicColumn)) {
      this.calculateWidthFromContent();
    }
  }

  private _header: GenericTableHeader[];
  private _data: GenericTableRow[];
  private _visibleData: GenericTableRow[] = [];
  private destroy$ = new Subject<void>();

  // checkbox management
  headerCheckbox = new FormControl<boolean>(null);
  checkboxes = new FormArray<boolean>([]);
  hasChecked = false;

  totalRows: number;

  ngOnInit(): void {
    // refresh table setup after all inputs were loaded
    this.header = this._header;
    this.data = this._data;

    this.checkboxes.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(values => {
      if (values.every(v => v)) {
        this.headerCheckbox.setValue(true, { emitEvent: false });
        this.hasChecked = true;
      } else {
        this.headerCheckbox.setValue(false, { emitEvent: false });
        this.hasChecked = values.includes(true);
      }
    });

    this.headerCheckbox.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        const { currentPage, itemsPerPage } = this.paginationConfig;

        getPageData<FormControl>(this.checkboxes.controls, currentPage, itemsPerPage).forEach(control => {
          control.setValue(true, { emitEvent: false });
        });
      } else {
        this.checkboxes.reset([], { emitEvent: false });
      }
      this.hasChecked = value;
    });

    this.tableButtons?.forEach(b => {
      b.__innerHtml = mixButtonIconText(b.text, b.icon);
    });

    this.tableSelectedButtons?.forEach(b => {
      b.__innerHtml = mixButtonIconText(b.text, b.icon);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get data() {
    return this._data;
  }

  get header() {
    return this._header;
  }

  get visibleData() {
    return this._visibleData;
  }

  get selectedRows(): GenericTableRow[] {
    const selectedRows: GenericTableRow[] = [];

    this.checkboxes.value.forEach((curChecked, index) => {
      if (curChecked) {
        selectedRows.push(this.visibleData[index]);
      }
    });

    return selectedRows;
  }

  set visibleData(visibleData: GenericTableRow[]) {
    this.checkboxes.clear();
    this.hasChecked = false;

    visibleData.forEach(() => {
      this.checkboxes.push(new FormControl<boolean>(null));
    });

    this._visibleData = visibleData;
    this.calculateWidthFromContent();
  }

  uncheckAllCheckboxes() {
    this.checkboxes.reset([], { emitEvent: false });
    this.headerCheckbox.reset(null, { emitEvent: false });
    this.hasChecked = false;
  }

  extractTextFn(row: GenericTableRow): string {
    return row.cells.reduce((acc, cur) => `${acc} ${cur.text} ${cur.subText}`, '');
  }

  updatePaginationLength(data: GenericTableRow[]) {
    this.paginationConfig.totalItems = data.length;
  }

  handlePageChange() {
    this.uncheckAllCheckboxes();
    this.calculateWidthFromContent();
  }

  calculateWidthFromContent() {
    const canvasContext = document.createElement('canvas').getContext('2d');

    if (!canvasContext) {
      return;
    }

    const widths: { [columnIndex: number]: number } = {};
    const { currentPage, itemsPerPage } = this.paginationConfig;

    getPageData<GenericTableRow>(this.visibleData, currentPage, itemsPerPage).forEach(row => {
      row.cells.forEach((cell, index) => {
        const textWidth = canvasContext.measureText(cell.text).width;
        const subTextWidth = canvasContext.measureText(cell.subText ?? '').width;

        widths[index] = Math.max(widths[index] ?? 0, textWidth, subTextWidth);
      });
    });

    // action button in the last column (if there are any)
    const actionBtn = document.querySelector('.actions-panel .btn');

    if (this.rowActions?.length && actionBtn) {
      const btnWidth = actionBtn.getBoundingClientRect().width;
      const btnMaxQnt = this.rowActions.length;

      widths[this.header.length - 1] = btnWidth * btnMaxQnt + ACTIONS_PANEL_GAP * (btnMaxQnt - 1);
    }

    this.header.forEach((col, index) => {
      if (col.dynamicColumn && widths[index]) {
        const headerWidth = canvasContext.measureText(col.text).width;
        const width = Math.max(headerWidth, widths[index]);

        col.width = width / BASE_FONT_SIZE + 'rem';
      }
    });
  }

  getCheckboxInCurrentPage(index: number) {
    const { itemsPerPage, currentPage } = this.paginationConfig;
    const newIndex = itemsPerPage * (currentPage - 1) + index;

    return this.checkboxes.at(newIndex);
  }

  getRowActionsAt(row: GenericTableRow) {
    return this.rowActions?.filter(action => !action.visible || action.visible(row));
  }

  evaluateTooltip(
    arg: string | ((...args: any[]) => string) | undefined,
    row: GenericTableRow,
    btnElement: HTMLButtonElement,
  ): any | undefined {
    return typeof arg === 'function' ? arg(row, btnElement) : arg;
  }
}
